import { API_URL } from '@inweb3/frontend/src/configs/config';
import { PaginationParams } from '../types/api/commun';
import axios from 'axios';
import { headerTokenUser } from './common';

export class BlogService {
  private endpointBlog = `${API_URL}/blog`;

  private static instance = new BlogService();

  public static getInstance(): BlogService {
    return this.instance;
  }

  async getAllBlogWithPagination(
    pagination: PaginationParams,
    selectedOption: string,
    search: string,
  ) {
    const { data, status } = await axios.get(this.endpointBlog + '/all', {
      params: {
        offset: pagination.offset,
        limit: pagination.limit,
        selectedOption: selectedOption,
        search: search,
      },
    });
    return { data, status };
  }

  async getAllBlogWithPaginationForAuthenticatedUser(
    pagination: PaginationParams,
    selectedOption: string,
    search: string,
    token: string,
  ) {
    const { data, status } = await axios.get(this.endpointBlog + '/user/all', {
      params: {
        offset: pagination.offset,
        limit: pagination.limit,
        selectedOption: selectedOption,
        search: search,
      },
      ...headerTokenUser(token),
    });
    console.log(data);
    return { data, status };
  }

  async getAllBookmarkedBlog(pagination: PaginationParams, token: string) {
    const { data, status } = await axios.get(
      this.endpointBlog + '/user/bookmark',
      {
        params: {
          offset: pagination.offset,
          limit: pagination.limit,
        },
        ...headerTokenUser(token),
      },
    );
    console.log(data);
    return { data, status };
  }
}
