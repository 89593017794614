import { typeRessourceItemEnum } from '../typeressource';

export interface BookType {
  index: number;
  name: string;
  website: string;
  price: number;
  type: typeRessourceItemEnum;
  author: string;
  image: string;
  authorTwitter: string;
}

export const books: BookType[] = [
  {
    index: 1,
    name: 'Mastering Bitcoin: Unlocking digital cryptocurrencies',
    website: 'https://www.amazon.com/dp/1491954388?tag=bestforc-20',
    price: 34.99,
    type: typeRessourceItemEnum.amazone,
    author: 'Andreas M. Antonopoulos',
    image:
      'https://cdn.codeforgeek.com/wp-content/uploads/mastering-bitcoin-233x300.jpg.webp',
    authorTwitter: 'https://twitter.com/aantonop',
  },
  {
    index: 2,
    name: 'Mastering Bitcoin: Unlocking digital cryptocurrencies',
    website:
      'https://www.youtube.com/watch?v=M576WGiDBdQ&ab_channel=freeCodeCamp.org',
    price: 0,
    type: typeRessourceItemEnum.amazone,
    author: 'Andreas M. Antonopoulos',
    image:
      'https://cdn.codeforgeek.com/wp-content/uploads/mastering-bitcoin-233x300.jpg.webp',
    authorTwitter: 'https://twitter.com/aantonop',
  },
];
