import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export interface CheckboxProps {
  id: string;
  classNameInput: string;
  classNameText: string;
  register: UseFormRegisterReturn;
  children: JSX.Element;
  errorMessage: string | undefined;
}

function CheckBoxInnoom({
  id,
  classNameInput,
  register,
  classNameText,
  children,
  errorMessage,
}: CheckboxProps) {
  return (
    <>
      <div className={'flex flex-row'}>
        <div className="flex flex-row items-center h-5">
          <input
            id={id}
            type="checkbox"
            value=""
            className={`${classNameInput} w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800`}
            {...register}
          />
        </div>
        <label
          htmlFor="remember"
          className={`${classNameText} ml-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
        >
          {children}
        </label>
      </div>
      <p
        className={
          'flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'
        }
      >
        {errorMessage}
      </p>
    </>
  );
}

export default CheckBoxInnoom;
