import { HiHome, HiOutlineBookmark } from 'react-icons/hi';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { endpoint } from '../../../navigation/endpoint';
import { SideBarItemType } from '../sidebar.type';

export const blogItemSideBar: SideBarItemType[] = [
  {
    indexSousTopic: 0,
    pro: false,
    icon: HiHome,
    className: `${textWhiteFontSemibold} hover:text-black`,
    endpoint: endpoint.blogs.home,
    title: 'Home',
    isLogged: false,
  },
  {
    indexSousTopic: 1,
    pro: false,
    icon: HiOutlineBookmark,
    className: textWhiteFontSemibold + ' hover:text-black',
    endpoint: endpoint.blogs.bookmarks,
    title: 'Bookmarks',
    isLogged: true,
  },
];
