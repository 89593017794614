import React from 'react';
import { BookType } from './books';

interface BookPropsType {
  book: BookType;
}

function BookCard({ book }: BookPropsType) {
  return (
    <div
      className={
        'flex flex-row max-h-38 bg-white rounded-lg shadow-md z-10 bg-gray-800 drop-shadow-md h-full p-3 '
      }
    >
      <div>
        <img src={book.image} className={'object-fill max-h-40'} />
      </div>
      <div>
        <p>{book.name}</p>
      </div>
    </div>
  );
}

export default BookCard;
