import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BlogsWithPaginationResponse } from '@inweb3/core/src/types/api/blog';
import { isAuth } from '@inweb3/core/src/utils/authentication';
import { requestServiceError } from '@inweb3/core/src/utils/general';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { useNavigate } from 'react-router-dom';
import { endpoint } from '../../../navigation/endpoint';
import { Spinner } from 'flowbite-react';
import { BlogService } from '@inweb3/core/src/services/blog.service';
import { logout } from '@inweb3/core/src/redux/user/user.slice';
import { mySelectors } from '../../../redux/selectors';
import BlogList from '@inweb3/component/src/common_layouts/blog/BlogList';

function BookmarkedBlogContainer() {
  const paginationLimit = 15;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bookMarkedBlogs, setBookMakedBlog] =
    useState<BlogsWithPaginationResponse>({
      blogs: [],
      totalBlogs: 0,
    });
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getBookmarkedBlogs() {
      await fetchBookmarkedBlogs();
    }
    getBookmarkedBlogs();
  }, []);

  const user = mySelectors().user;

  const fetchBookmarkedBlogs = async () => {
    console.log('fetch boomarked blogs');
    if (hasMore) {
      try {
        const token = isAuth();
        // TODO if token null then apply the eveny bus thing
        if (token === null) {
          dispatch(logout());
          navigate(endpoint.blogs.home, {
            replace: true,
          });
          return;
        }
        setLoading(true);
        const { data } = await BlogService.getInstance().getAllBookmarkedBlog(
          {
            offset: offset,
            limit: offset + paginationLimit,
          },
          token,
        );

        const blogsToSave: BlogsWithPaginationResponse = {
          blogs: bookMarkedBlogs.blogs.concat(data.blogs),
          totalBlogs: data.totalBlogs,
        };
        if (blogsToSave.blogs.length >= blogsToSave.totalBlogs) {
          setHasMore(false);
        } else {
          setOffset(offset + paginationLimit);
        }
        setBookMakedBlog(blogsToSave);
      } catch (err) {
        console.log(err);
        requestServiceError(err);
      }
    } else {
      console.log('dont has more');
    }
    setLoading(false);
  };

  const renderBookmarkedBlogList = () => {
    if (loading) {
      return (
        <div className={'w-full flex justify-center items-center h-full'}>
          <Spinner className="mt-3" size="md" light={true} />
        </div>
      );
    }
    if (bookMarkedBlogs.blogs.length > 0) {
      return (
        <BlogList
          scrollId={'scrollbaleLikedBlogDiv'}
          fetchBlogs={fetchBookmarkedBlogs}
          hasMore={hasMore}
          blogs={bookMarkedBlogs}
          logout={() => {
            console.log('logout');
          }}
          user={user}
        />
      );
    } else {
      return (
        <div className={'flex flex-1 justify-center items-center'}>
          <h3 className={textWhiteFontSemibold}>No Bookmarked Blog Found</h3>
        </div>
      );
    }
  };

  return (
    <div className={'flex flex-col max-h-[calc(100vh_-_5rem) w-full'}>
      <div>
        <div className={'flex flex-row flex-3 my-10 px-20'}>
          <div className={'flex flex-1 items-center justify-start'}>
            <h1 className={'text-white font-semibold'}>
              Bookmarked Web3 Blogs
            </h1>
          </div>
        </div>
      </div>
      <div
        id={'scrollbaleLikedBlogDiv'}
        className={'overflow-y-scroll scrollbar-hide'}
      >
        {renderBookmarkedBlogList()}
      </div>
    </div>
  );
}

export default BookmarkedBlogContainer;
