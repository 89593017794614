import React from 'react';
import { books } from './books';
import BookCard from './BookCard';

function BooksDevContainer() {
  return (
    <div
      className={
        'md:p-5 flex flex-col scrollbar-hide max-h-[calc(100vh_-_166px)] min-h-[calc(100vh_-_166px)] md:max-h-[calc(100vh_-_136px)] md:min-h-[calc(100vh_-_136px)] w-full overflow-y-scroll'
      }
    >
      <div
        className={
          'grid grid-cols-2 gap-4 auto-rows-max auto-cols-max grid-flow-row-dense'
        }
      >
        {books.map((book) => {
          return (
            <div key={book.index}>
              <BookCard book={book} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BooksDevContainer;
