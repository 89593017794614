import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { userService } from '@inweb3/core/src/services/user.service';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import LoadingButtonInnoom from "@inweb3/component/src/component/LoadingButtonInnoom";

function SignupConfirmPage() {
  const { token } = useParams() as { token: string };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  console.log(token);
  useEffect(() => {
    signupConfirm();
  }, []);

  const signupConfirm = async () => {
    setIsLoading(true);
    try {
      const { data } = await userService.confirmEmail(token);
      setIsLoading(false);
      setMessage(data.message);
    } catch (err) {
      setIsLoading(false);
      setMessage('there was an error, contact us on neddar_islam@innoom.com');
    }
    /*toast.loading('you will be redirected to home page', {
      duration: 3000,
    });
    await sleep(3000);
    navigate('/' + endpoint.blogs, { replace: true });*/
  };

  return (
    <div
      className={
        'flex flex-1 items-center justify-center min-h-screen max-h-[calc(100vh_-_5rem)pt-20 '
      }
    >
      {isLoading ? (
        <LoadingButtonInnoom />
      ) : (
        <p className={`${textWhiteFontSemibold} text-12`}>{message}</p>
      )}
    </div>
  );
}

export default SignupConfirmPage;
