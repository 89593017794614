import React from 'react';

interface ContentBodyProps {
  children: JSX.Element;
  applyOverflow: boolean;
}

function ContainerBody({ children, applyOverflow }: ContentBodyProps) {
  return (
    <div
      className={`px-5 flex max-h-[calc(100vh_-_196px)] min-h-[calc(100vh_-_196px)] md:min-h-[calc(100vh_-_136px)]  md:max-h-[calc(100vh_-_136px)] ${
        applyOverflow ? 'overflow-y-scroll scrollbar-hide' : ''
      }`}
    >
      {children}
    </div>
  );
}

export default ContainerBody;
