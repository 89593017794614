import React from 'react';
import { Button, Color, Spinner } from 'flowbite-react';

export interface ButtonInnoom {
  title: string;
  click: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled: boolean;
  loading: boolean;
  color?: Color;
  className?: string;
}

function ButtonInnoom({
  title,
  click,
  disabled,
  loading,
  color,
  className,
}: ButtonInnoom) {
  return (
    <Button
      type="submit"
      onClick={click}
      disabled={disabled}
      color={color || 'blue'}
      className={className}
    >
      {loading ? (
        <>
          <Spinner className="mr-3" size="sm" light={true} />
          Loading
        </>
      ) : (
        <>{title}</>
      )}
    </Button>
  );
}

export default ButtonInnoom;
