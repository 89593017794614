import { typeRessourceItemEnum } from '../typeressource';

export interface TutorialTypes {
  index: number;
  name: string;
  website: string;
  price?: number;
  type: typeRessourceItemEnum;
}

export const tutorials = [
  {
    index: 2,
    name: 'Solidity, Blockchain, and Smart Contract Course – Beginner to Expert Python Tutorial',
    website:
      'https://www.youtube.com/watch?v=M576WGiDBdQ&ab_channel=freeCodeCamp.org',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 3,
    name: 'The Complete Guide to Full Stack Ethereum Development - Tutorial for Beginners [2021]',
    website: 'https://www.youtube.com/watch?v=a0osIaAOFSE',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 4,
    name: 'Ultimate NFT Programming Tutorial - FULL COURSE',
    website:
      'https://www.youtube.com/watch?v=tBMk1iZa85Y&ab_channel=MoralisWeb3',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 6,
    name: 'Hardhat Tutorial | Smart contract framework',
    website: 'https://www.youtube.com/watch?v=9Qpi80dQsGU',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 9,
    name: 'MetaMask Tutorial for Beginners - How to Set Up MetaMask',
    website: 'https://www.youtube.com/watch?v=Af_lQ1zUnoM',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 10,
    name: 'Compile, Test & Deploy Smart contracts to any EVM Blockchain',
    website: 'https://www.youtube.com/watch?v=62f757RVEvU',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 14,
    name: 'Create Your First Smart Contract',
    website: 'https://www.web3.university/tracks/create-a-smart-contract',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 15,
    name: 'Web3 tutorial from EatTheBlocks',
    website:
      'https://www.youtube.com/playlist?list=PLbbtODcOYIoFs0PDlTdxpEsZiyDR2q9aA',
    price: 0,
    type: typeRessourceItemEnum.youtubePlaylist,
  },
  {
    index: 16,
    name: 'Create ERC20 token on ethereum by EatTheBlocks',
    website: 'ZLFiGHIxS1c',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 17,
    name: 'Build Your First Blockchain App Using Ethereum Smart Contracts and Solidity',
    website: 'coQ5dg8wM2o',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 18,
    name: 'How to Build a Full Stack NFT Marketplace on Ethereum with Polygon and Next.js - [2021 Tutorial]',
    website: 'GKJBEEXUha',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 19,
    name: 'How to Build Ethereum Dapp (Decentralized Application Development Tutorial)',
    website: '3681ZYbDSSk',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 20,
    name: 'How to make NFT Art with On-Chain Metadata | FULL HARDHAT / JS TUTORIAL! (w/ Polygon & Opensea)',
    website: '9oERTH9Bkw0',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 21,
    name: 'Building a Complete Web3 Next.js dApp | Moralis Web3 Full Course',
    website: 'nfYCSodsdn8',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 22,
    name: 'Blockchain Tutorial for Beginners | Build a DeFi App (Ethereum, Solidity, Web3.js & Truffle)',
    website: 'CgXQC4dbGUE',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 23,
    name: 'Ethereum Programming for Beginners - How to Create Your First dApp (FULL COURSE)',
    website: 'jdx2H1alijQ',
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 24,
    name: 'Creating a simple blockchain in Python',
    website:
      'https://openquest.xyz/quest/creating-a-simple-blockchain-in-python',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 25,
    name: 'How to Build Ethereum Dapp With IPFS - Blockchain Programming Tutorial',
    website: 'pTZVoqBUjvI',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 26,
    name: 'Why Sign-In with Ethereum is a Game-Changer - Part 1',
    website:
      'https://blog.spruceid.com/sign-in-with-ethereum-is-a-game-changer-part-1/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 27,
    name: 'starknet-tutorial',
    website: 'https://github.com/cryptobenkei/starknet-tutorial',
    type: typeRessourceItemEnum.github,
  },
  {
    index: 28,
    name: 'Learn how to code elliptic curve cryptography',
    website:
      'https://medium.com/coinmonks/learn-how-to-code-elliptic-curve-cryptography-a952dfdc20ab',
    type: typeRessourceItemEnum.medium,
  },
  {
    index: 29,
    name: 'Gas Optimizations for the Rest of Us',
    website:
      'https://m1guelpf.blog/d0gBiaUn48Odg8G2rhs3xLIjaL8MfrWReFkjg8TmDoM',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 30,
    name: 'Ethernaut x Foundry - 0x0 Hello Ethernaut',
    website: 'https://blog.0xeval.me/ethernaut-x-foundry-0x0-hello-ethernaut',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 31,
    name: 'Finally authenticating Rails users with MetaMask',
    website:
      'https://dev.to/q9/finally-authenticating-rails-users-with-metamask-3fj',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 32,
    name: 'Sign-In with Ethereum',
    website: 'https://wagmi.sh/examples/sign-in-with-ethereum.en-US',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 33,
    name: 'Explore Ethereum Data with SQL queries on Dune Analytics',
    website:
      'https://ethereumdev.io/explore-ethereum-data-with-sql-queries-on-dune-analytics/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 34,
    name: 'Voting system',
    website: 'https://www.cairo-lang.org/docs/hello_cairo/voting.html',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 35,
    name: 'Unit tests [Contract]',
    website: 'https://perama-v.github.io/cairo/examples/unit_test/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 36,
    name: 'How to use Dapptools | Code like MakerDAO',
    website:
      'https://medium.com/@patrick.collins_58673/how-to-use-dapptools-code-like-makerdao-fed9909d055b',
    type: typeRessourceItemEnum.medium,
  },
  {
    index: 37,
    name: 'Arbitrum Tutorials',
    website: 'https://github.com/OffchainLabs/arbitrum-tutorials',
    type: typeRessourceItemEnum.github,
  },
  {
    index: 38,
    name: 'Get to know Ethereum Calldata',
    website:
      'https://medium.com/laika-lab/get-to-know-ethereum-calldata-72f65e8bffef',
    type: typeRessourceItemEnum.medium,
  },
  {
    index: 39,
    name: 'Introduction to Ethereum Rollups',
    website:
      'https://www.quicknode.com/guides/infrastructure/introduction-to-ethereum-rollups',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 40,
    name: 'Quick tour on Ethereum private keys attacks',
    website:
      'https://medium.com/@pierreia/quick-tour-on-ethereum-private-keys-attacks-3082846b7632',
    type: typeRessourceItemEnum.medium,
  },
  {
    index: 41,
    name: 'Advanced MultiSwap: How to better arbitrage with Solidity',
    website: 'https://soliditydeveloper.com/multiswap-advanced',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 42,
    name: 'Hacking the Blockchain: Ethereum',
    website:
      'https://medium.com/immunefi/hacking-the-blockchain-an-ultimate-guide-4f34b33c6e8b',
    type: typeRessourceItemEnum.medium,
  },
  {
    index: 43,
    name: 'SMTChecker, Remix & Dapptools',
    website: 'https://fv.ethereum.org/2021/12/01/smtchecker-dapptools/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 44,
    name: 'https://www.youtube.com/watch?v=pdsYCkUWrgQ&ab_channel=PatrickCollins',
    website: 'pdsYCkUWrgQ',
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 45,
    name: 'How to Build a Multi-Chain NFT Marketplace on Ethereum, Polygon and Binance Smart Chain using Solidity, React, Hardhat and Ethers.js',
    website:
      'https://atila.ca/blog/tomiwa/how-to-build-a-multi-chain-nft-marketplace-on-ethereum-polygon-and-binance-smart-chain-using-solidity-react-hardhat-and-ethersjs',
    type: typeRessourceItemEnum.website,
  },
];
