import React from 'react';
import { Modal } from 'flowbite-react';
import { useDispatch } from 'react-redux';
import { mySelectors } from '../../redux/selectors';
import { toggleContactModalOpen } from '@inweb3/core/src/redux/system/system.slice';

function ContactUs() {
  const dispatch = useDispatch();
  const system = mySelectors().system;
  return (
    <Modal
      show={system.contactModalOpen}
      onClose={() => {
        dispatch(toggleContactModalOpen(false));
      }}
    >
      <Modal.Header>
        <h2>Our Contacts</h2>
      </Modal.Header>
      <Modal.Body>
        <p className={'pb-5 font-bold'}>
          Email :{' '}
          <span className={'text-blue-900 font-semibold'}>
            support@innoom.com
          </span>
        </p>
        <p className={'pb-5 font-bold'}>
          Facebook :{' '}
          <span className={'text-blue-900 font-semibold'}>
            <a
              href={'https://www.facebook.com/InWeb3-107932818633892'}
              target={'_blank'}
              rel="noreferrer"
            >
              @inweb3
            </a>
          </span>
        </p>
        <p className={'pb-5 font-bold'}>
          Twitter :{' '}
          <span className={'text-blue-900 font-semibold'}>
            <a
              href={'https://twitter.com/inWeb_3'}
              target={'_blank'}
              rel="noreferrer"
            >
              @inweb3
            </a>
          </span>
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default ContactUs;
