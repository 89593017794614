import { requestService } from './request';

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const requestServiceError = (err: any, logout?: () => void) => {
  const data = requestService.getMessageFromErrorAxios(err);
  if (data.status === 401 || data.status === 403) {
    //eventBus.dispatch(eventBusType.LOGOUT, { details: {} });
    if (logout) {
      logout();
    }
  }
  console.log(data.message);
  return data;
};
