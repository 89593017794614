import { endpoint } from '../navigation/endpoint';
import Main from '../pages/main/Main';
import BlogContainer from '../pages/blog/BlogContainer';
import ListingBlogsContainer from '../pages/blog/listing/ListingBlogsContainer';
import BookmarkedBlogContainer from '../pages/blog/bookmark_blog/BookmarkedBlogContainer';

import React from 'react';
import JobBoardContainer from '../pages/joboboard/JobBoardContainer';
import SignupConfirmPage from '../pages/auth/signup/SignupConfirmPage';
import SignupPage from '../pages/auth/signup/SignupPage';
import NewPasswordPage from '../pages/auth/new_password/NewPasswordPage';
import ResetPassword from '../pages/auth/new_password/ResetPassword';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { Route } from 'react-router-dom';
import PodcastContainer from "../pages/podcast/PodcastContainer";
import PodcastHomeContainer from '../pages/podcast/PodcastHomeContainer';
import PrivacyPolicy from '../pages/privacy_policy/PrivacyPolicy';
import TermOfUse from "../pages/TermOfUse";
import DevsContainer from '../pages/devs/DevsContainer';
import CoursesDevsContainer from '../pages/devs/courses/CoursesDevsContainer';
import TutorialsDevsContainer from "../pages/devs/tutorials/TutorialsDevsContainer";
import BooksDevContainer from "../pages/devs/books/BooksDevContainer";

interface RouteContainerType {
  route: RouteType;
}

interface RouteType {
  path: string;
  component: JSX.Element;
  sousRoutes: RouteType[];
  key: string;
  private: boolean;
}

export const RoutesEndpoint: RouteType = {
  path: endpoint.root,
  key: 'MAIN',
  component: <Main></Main>,
  private: false,
  sousRoutes: [
    {
      path: endpoint.blogs.root,
      key: 'BLOG',
      private: false,
      component: <BlogContainer />,
      sousRoutes: [
        {
          path: endpoint.blogs.home,
          component: <ListingBlogsContainer />,
          private: false,
          key: 'LISTING_BLOG',
          sousRoutes: [],
        },
        {
          path: endpoint.blogs.bookmarks,
          component: <BookmarkedBlogContainer />,
          private: false,
          sousRoutes: [],
          key: 'BOOKMARKED_BLOG',
        },
      ],
    },
    {
      path: endpoint.devs.root,
      key: 'Devs',
      private: false,
      component: <DevsContainer />,
      sousRoutes: [
        {
          path: endpoint.devs.courses,
          component: <CoursesDevsContainer />,
          private: false,
          key: 'DEV_COURSES',
          sousRoutes: [],
        },
        {
          path: endpoint.devs.tutorials,
          component: <TutorialsDevsContainer />,
          private: false,
          key: 'DEV_Tutorials',
          sousRoutes: [],
        },
        {
          path: endpoint.devs.books,
          component: <BooksDevContainer />,
          private: false,
          key: 'DEV_BOOKS',
          sousRoutes: [],
        },
      ],
    },
    {
      path: endpoint.jobboard.root,
      key: 'JOB_BOARD',
      component: <JobBoardContainer />,
      private: false,
      sousRoutes: [
        {
          path: endpoint.jobboard.home,
          component: <p className={textWhiteFontSemibold}>jobBoard</p>,
          private: false,
          key: 'JOB_BOARD_HOME',
          sousRoutes: [],
        },
      ],
    },
    {
      path: endpoint.podcasts.root,
      key: 'PODCAST',
      component: <PodcastContainer />,
      private: false,
      sousRoutes: [
        {
          path: endpoint.podcasts.home,
          component: <PodcastHomeContainer/>,
          private: false,
          key: 'PODCAST_HOME',
          sousRoutes: [],
        },
      ],
    },
    {
      path: `${endpoint.signup_confirm}/:token`,
      key: 'SIGNUP_CONFIRM_PAGE',
      component: <SignupConfirmPage />,
      private: false,
      sousRoutes: [],
    },
    {
      path: endpoint.signup,
      key: 'SIGNUP',
      component: <SignupPage />,
      private: false,
      sousRoutes: [],
    },
    {
      path: endpoint.newpassword,
      key: 'NEW_PASSWORD_PAGE',
      component: <NewPasswordPage />,
      private: false,
      sousRoutes: [],
    },
    {
      path: `${endpoint.reset_password}/:token`,
      key: 'RESET_PASSOWORD',
      component: <ResetPassword />,
      private: false,
      sousRoutes: [],
    },
    {
      path: `${endpoint.privacyPolicy}`,
      key: 'PRIVACY_POLICY',
      component: <PrivacyPolicy />,
      private: false,
      sousRoutes: [],
    },
    {
      path: `${endpoint.conditionandterms}`,
      key: 'TERM_USE',
      component: <TermOfUse />,
      private: false,
      sousRoutes: [],
    },
  ],
};

function routeWithSubRoutes(route: RouteType) {
  return (
    <Route path={route.path} element={route.component}>
      {route.sousRoutes.map((sousRoute) => {
        return routeWithSubRoutes(sousRoute);
      })}
    </Route>
  );
}

export default routeWithSubRoutes;
