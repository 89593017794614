import React from 'react';

function PodcastHomeContainer() {
  return (
    <div className={'px-5 pt-20 max-h-[calc(100vh_-_5rem) min-h-screen'}>
      <p>podcast home container</p>
    </div>
  );
}

export default PodcastHomeContainer;
