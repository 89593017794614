import { SideBarItemType } from '../sidebar.type';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { endpoint } from '../../../navigation/endpoint';
import { MdCastForEducation } from "react-icons/md";
import { SiCoursera } from "react-icons/si";

export const devSideBarItem: SideBarItemType[] = [
  {
    indexSousTopic: 0,
    pro: false,
    icon: SiCoursera,
    className: textWhiteFontSemibold + ' hover:text-black',
    endpoint: endpoint.devs.courses,
    title: 'Courses',
    isLogged: false,
  },
  {
    indexSousTopic: 1,
    pro: false,
    icon: MdCastForEducation,
    className: textWhiteFontSemibold + ' hover:text-black',
    endpoint: endpoint.devs.tutorials,
    title: 'Tutorials',
    isLogged: false,
  },
  /*{
    indexSousTopic: 2,
    pro: false,
    icon: GiWhiteBook,
    className: textWhiteFontSemibold + ' hover:text-black',
    endpoint: endpoint.devs.books,
    title: 'Books',
    isLogged: false,
  },*/
];
