import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export interface SelectInnoomProps {
  children: JSX.Element;
  register: UseFormRegisterReturn;
}

function SelectInnoom({ children, register }: SelectInnoomProps) {
  return (
    <select
      defaultValue={0}
      {...register}
      className={
        'block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm'
      }
    >
      {children}
    </select>
  );
}

export default SelectInnoom;
