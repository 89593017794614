import React from 'react';
import ToastErrorDefault from './ToastErrorDefault';

export interface ErrorReturnFormInnoom {
  error: string | null;
}

function ErrorReturnFormInnoom({ error }: ErrorReturnFormInnoom) {
  if (error !== null) {
    return (
      <div className={'w-full'}>
        <ToastErrorDefault message={error} />
      </div>
    );
  } else {
    return null;
  }
}

export default ErrorReturnFormInnoom;
