import React from 'react';
import FormLogin from './FormLogin';
import { Modal } from 'flowbite-react';
import { SystemState } from '@inweb3/core/src/redux/system/system.slice';

export interface LoginProps {
  closeModalProps: () => void;
  lostPasswordClick: () => void;
  passToSignupProps: () => void;
  system: SystemState;
}

function Login({
  system,
  lostPasswordClick,
  passToSignupProps,
  closeModalProps,
}: LoginProps) {
  const closeModal = () => {
    closeModalProps();
  };

  return (
    <Modal
      show={system.loginModalOpen}
      onClose={() => {
        closeModal();
      }}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
          Sign in to our platform
        </h3>
        <FormLogin
          lostPasswordClick={() => {
            lostPasswordClick();
          }}
          passToSignupProps={() => {
            passToSignupProps();
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

export default Login;
