import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SystemState {
  loginModalOpen: boolean;
  sideBarMobileOpen: boolean;
  contactModalOpen: boolean;
}

const initialState: SystemState = {
  loginModalOpen: false,
  sideBarMobileOpen: false,
  contactModalOpen: false,
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    openLoginModal: (state, action: PayloadAction<boolean>) => {
      state.loginModalOpen = action.payload;
    },
    toggleSideBarOpenMobile: (state, action: PayloadAction<boolean>) => {
      state.sideBarMobileOpen = action.payload;
    },
    toggleContactModalOpen: (state, action: PayloadAction<boolean>) => {
      state.contactModalOpen = action.payload;
    },
  },
});

export const { openLoginModal, toggleSideBarOpenMobile, toggleContactModalOpen } = systemSlice.actions;

export default systemSlice.reducer;
