import React from 'react';
import NewPasswordForm from './NewPasswordForm';

function NewPasswordPage() {
  return (
    <div
      className={
        'pt-28 signup_container min-h-screen max-h-[calc(100vh_-_5rem)'
      }
    >
      <div className={'flex flex-col items-center justify-center'}>
        <h3 className="mb-4 text-xl font-medium text-white dark:text-white">
          Enter you email to get the link to change your password
        </h3>
      </div>
      <NewPasswordForm></NewPasswordForm>
    </div>
  );
}

export default NewPasswordPage;
