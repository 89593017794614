import React, { useState } from 'react';
import { CourseTypes } from './courses_data';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { typeRessourceItemEnum } from '../typeressource';
import { TbBrandYoutube } from 'react-icons/tb';
import { CgPlayList, CgWebsite } from 'react-icons/cg';
import { Tooltip } from 'flowbite-react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
interface CourseCardProps {
  course: CourseTypes;
}

function CourseCard({ course }: CourseCardProps) {
  const [open, setOpen] = useState(false);
  const [currentVideoToPlay, setCurrentVideoToPlay] = useState('');
  const renderIcons = (course: CourseTypes) => {
    switch (course.type) {
      case typeRessourceItemEnum.website:
        return <CgWebsite className={'text-white h-7 w-5'} />;
      case typeRessourceItemEnum.youtube:
        return <TbBrandYoutube className={'text-white h-7 w-5'} />;
      case typeRessourceItemEnum.youtubePlaylist:
        return <CgPlayList className={'text-white h-7 w-5'} />;
    }
  };

  return (
    <>
      <ModalVideo
        channel="youtube"
        isOpen={open}
        youtube={{
          autoplay: 1,
        }}
        allowFullScreen={true}
        videoId={currentVideoToPlay}
        onClose={() => setOpen(false)}
      />
      <div
        className={
          'bg-white rounded-lg shadow-md z-10 bg-gray-800 drop-shadow-md h-full p-3 flex flex-col cursor-pointer'
        }
        onClick={() => {
          if (course.type !== typeRessourceItemEnum.youtube) {
            window.open(course.website, '_blank');
          } else {
            setCurrentVideoToPlay(course.website);
            setOpen(true);
          }
        }}
      >
        <div>
          <p className={textWhiteFontSemibold}>{course.name}</p>
        </div>

        <div className={'flex flex-1'}></div>
        <div className={'flex flex-row justify-between'}>
          <div></div>
          <div className={'flex items-center justify-center'}>
            <Tooltip content={course.type}>{renderIcons(course)}</Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseCard;
