export interface SelectedOptionsForFilterBlogItemType {
  value: string;
  label: string;
}

export const selectedOptionsForFilterBlog: SelectedOptionsForFilterBlogItemType[] =
  [
    { value: 'All', label: 'All' },
    { value: 'Followed', label: 'Followed' },
    //{ value: 'Trends', label: 'Trends' },
  ];
