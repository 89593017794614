export const endpoint = {
  root: '/',
  blogs: {
    topic: 'blogs',
    root: '/blogs',
    home: '/blogs/home',
    follow_blog: '/blogs/follow_blog',
    likes: '/blogs/likes',
    bookmarks: '/blogs/bookmarks',
    history: '/blogs/history',
    adding_blog: '/blogs/adding_blog',
    promote_blog: '/blogs/promote_blog',
  },
  jobboard: {
    topic: 'jobboard',
    root: '/jobboard',
    home: '/jobboard/home',
  },
  podcasts: {
    topic: 'podcasts',
    root: '/podcasts',
    home: '/podcasts/home',
  },
  devs: {
    topic: 'devs',
    root: '/devs',
    home: '/devs/home',
    courses: '/devs/courses',
    tutorials: '/devs/tutorials',
    books : "/devs/books"
  },
  feed: 'feed',
  signup: '/signup',
  conditionandterms: '/condition-and-terms',
  newpassword: '/new_password',
  signup_confirm: '/signup_confirm',
  reset_password: '/reset_password',
  about: '/about',
  privacyPolicy: '/privacy_policy',
};
