import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from "../../types/user/user.type";

const initialState: UserState = {
  email: null,
  username: null,
  isLogged: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserState>) => {
      state.email = action.payload.email;
      state.username = action.payload.username;
    },
    updateLoginSuccess: (state, action: PayloadAction<UserState>) => {
      console.log('update success login');
      state.isLogged = action.payload.isLogged;
      state.email = action.payload.email;
      state.username = action.payload.username;
    },
    logout: (state, action: PayloadAction) => {
      state.isLogged = false;
      state.email = null;
      state.username = null;
    },
  },
});

export const { login, updateLoginSuccess, logout } = userSlice.actions;

export default userSlice.reducer;
