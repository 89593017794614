import React, { useState } from 'react';
import { emailValidation } from '@inweb3/core/src/validations';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { userService } from '@inweb3/core/src/services/user.service';
import { toast } from 'react-hot-toast';
import { sleep } from '@inweb3/core/src/utils/general';
import { useNavigate } from 'react-router-dom';
import { endpoint } from '../../../navigation/endpoint';
import { requestService } from '@inweb3/core/src/utils/request';
import ErrorReturnFormInnoom from '@inweb3/component/src/component/ErrorReturnFormInnoom';
import InputInnoom from '@inweb3/component/src/component/InputInnoom';
import ButtonInnoom from '@inweb3/component/src/component/ButtonInnoom';

export interface NewPasswordFormInputs {
  email: string;
}

const newPasswordValidation = yup.object({
  email: emailValidation,
});

function NewPasswordForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewPasswordFormInputs>({
    resolver: yupResolver(newPasswordValidation),
  });

  const onSubmit: SubmitHandler<NewPasswordFormInputs> = async (input) => {
    setIsLoading(true);
    try {
      await userService.demandeChangePassword(input.email);
      reset({
        email: '',
      });
      toast.success(
        'If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.',
        {
          duration: 5000,
        },
      );
      toast.loading('you will be redirected to home page', {
        duration: 5000,
      });
      await sleep(5000);
      navigate('/' + endpoint.blogs.root);
    } catch (err) {
      const data = requestService.getMessageFromErrorAxios(err);
      console.log(data.message);
      toast.error(data.message);
      setError(data.message);
    }
    setIsLoading(false);
  };

  return (
    <div className={'flex flex-col'}>
      <ErrorReturnFormInnoom error={error} />
      <form
        className="flex flex-col gap-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputInnoom
          className={''}
          classNameLabel={'text-white'}
          id={'email_new_password'}
          type={'text'}
          placeholder={'example@email.com'}
          labelTitle={'Email'}
          errorMessage={errors.email?.message}
          register={register('email')}
        ></InputInnoom>
        <ButtonInnoom
          disabled={isLoading}
          title={'reset your password'}
          loading={isLoading}
          click={() => {
            console.log('button signup');
          }}
        />
      </form>
    </div>
  );
}

export default NewPasswordForm;
