import cookie from 'js-cookie';
import { GetUserJobResponse } from '../types/api/userjob';

export const storagekeys = {
  token: 'token',
  userJobs: 'userJobs',
  topic: 'topic',
};

// Set in Cookie
export const setCookieWithExp = (
  key: string,
  value: string,
  expire: number,
) => {
  if (window !== undefined) {
    cookie.set(key, value, {
      // 1 Day
      expires: expire,
    });
  }
};

export const setCookie = (key: string, value: string) => {
  if (window !== undefined) {
    cookie.set(key, value);
  }
};
// remove from cookie
export const removeCookie = (key: string) => {
  if (window !== undefined) {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

export const getCookie = (key: string) => {
  if (window !== undefined) {
    return cookie.get(key);
  }
  return null;
};

// Set in localstorage
export const setLocalStorage = (key: string, value: any) => {
  if (window !== undefined) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// Remove from localstorage
export const removeLocalStorage = (key: string) => {
  if (window !== undefined) {
    localStorage.removeItem(key);
  }
};

export const getLocalStorage = (key: string) => {
  const localData = localStorage.getItem(key);
  if (localData !== null) {
    return JSON.parse(localData);
  } else {
    return null;
  }
};
// Set in localstorage
export const setSessionStorage = (key: string, value: string) => {
  if (window !== undefined) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

// Remove from localstorage
export const removeSessionStorage = (key: string) => {
  if (window !== undefined) {
    sessionStorage.removeItem(key);
  }
};
// Auth enticate user by passing data to cookie and localstorage during signin
export const authenticate = (token: string) => {
  console.log('AUTHENTICATE HELPER ON SIGNIN RESPONSE', token);
  setCookie(storagekeys.token, token);
};

// Access user info from localstorage
export const isAuth = (): string | null => {
  if (window !== undefined) {
    const cookieChecked = getCookie(storagekeys.token);
    if (cookieChecked) {
      return cookieChecked;
    }
  }
  return null;
};

export const signout = (next: CallableFunction) => {
  removeCookie('token');
  // removeLocalStorage('user');
  next();
};

// / Token
export const getToken = () => {
  return getCookie(storagekeys.token);
};

export const setToken = (token: string) => {
  setCookie(storagekeys.token, token);
};

export const removeToken = () => {
  removeCookie(storagekeys.token);
};

// user jobs
export const getUserJobsFromLocalStorage = () => {
  return getLocalStorage(storagekeys.userJobs);
};

export const setUserJobsFromLocalStorage = (
  listUserJobs: GetUserJobResponse[],
) => {
  return setLocalStorage(storagekeys.userJobs, listUserJobs);
};

// topic
export const getTopic = () => {
  return getLocalStorage(storagekeys.topic);
};

export const setTopic = (topicIndex: number) => {
  return setLocalStorage(storagekeys.topic, topicIndex);
};
