import React from 'react';
import { Avatar, Button, Dropdown } from 'flowbite-react';
import NavItem from './NavItem';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { GrClose } from 'react-icons/gr';
import { UserState } from '@inweb3/core/src/types/user/user.type';
import { SystemState } from '@inweb3/core/src/redux/system/system.slice';
import { TopicNavBarType } from '@inweb3/core/src/types/topic.type';

export interface NavBarPropsType {
  user: UserState;
  system: SystemState;
  topicOn: boolean;
  login?: React.MouseEventHandler<HTMLButtonElement>;
  signup?: React.MouseEventHandler<HTMLButtonElement>;
  logoutClick: () => void;
  logoClick: () => void;
  openMobileMenu: () => void;
  closeMobileMenu: () => void;
  changeTopicClick: (index: number, endpoint: string) => void;
  currentTopicIndex?: number;
  logoImage: string;
  topicsForNavBar: TopicNavBarType[];
  componentSideBar: JSX.Element;
}

function NavBar({
  user,
  system,
  login,
  signup,
  logoutClick,
  topicOn,
  logoClick,
  openMobileMenu,
  closeMobileMenu,
  changeTopicClick,
  currentTopicIndex,
  logoImage,
  topicsForNavBar,
  componentSideBar,
}: NavBarPropsType) {
  /**
   * component
   */
  const renderUserPart = () => {
    if (user.isLogged) {
      return (
        <div className="hidden md:flex flex-1 justify-end order-2 ">
          <div className={'pr-4'}>
            <Dropdown
              arrowIcon={false}
              inline={true}
              className={'pr-4'}
              label={
                <Avatar
                  alt="User settings"
                  img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                  rounded={true}
                />
              }
            >
              <Dropdown.Header>
                <span className="block text-sm">{user.username}</span>
                <span className="block truncate text-sm font-medium">
                  {user.email}
                </span>
              </Dropdown.Header>
              <Dropdown.Item
                onClick={() => {
                  logoutClick();
                }}
              >
                Sign out
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      );
    } else {
      return (
        <div className="hidden md:flex flex-1 flex-row w-auto items-center justify-end pr-4">
          <Button
            className={
              'px-3 mr-3 focus:ring-0 border-blue-600 bg-transparent w-full'
            }
            onClick={login}
          >
            Login
          </Button>
          <Button
            className={'px-3 border-0 focus:ring-0 border-blue-600 w-full'}
            color={'blue'}
            onClick={signup}
          >
            Signup
          </Button>
        </div>
      );
    }
  };

  const topicsMobile = () => {
    return (
      <ul className={'md:hidden flex flex-1 z-30 justify-center'}>
        <Dropdown arrowIcon={true} inline={true} label={'Discover'}>
          {topicsForNavBar.map((value) => {
            return (
              <Dropdown.Item key={value.key}>
                <NavItem
                  content={value.content}
                  soon={value.soon}
                  index={value.index}
                  changeTopicClick={(index) => {
                    changeTopicClick(index, value.endpoint);
                  }}
                  currentTopicIndex={currentTopicIndex! || 0}
                />
              </Dropdown.Item>
            );
          })}
        </Dropdown>
      </ul>
    );
  };

  const sideBarXMobile = () => {
    return (
      <ul
        className={` ${
          system.sideBarMobileOpen ? 'left-0' : '-left-full'
        } fixed md:hidden bottom-0 top-20 bg-gray-700 w-10/12 space-y-5 p-2 h-screen bg-opacity-95 
        transition-left`}
      >
        {componentSideBar}
      </ul>
    );
  };

  const topicsLaptop = () => {
    return (
      <ul
        className={
          'hidden md:flex md:flex-1 justify-center flex-row space-x-4'
        }
      >
        {topicsForNavBar.map((value) => {
          return (
            <NavItem
              key={value.key}
              content={value.content}
              soon={value.soon}
              index={value.index}
              changeTopicClick={(index) => {
                changeTopicClick(index, value.endpoint);
              }}
              currentTopicIndex={currentTopicIndex! || 0}
            ></NavItem>
          );
        })}
      </ul>
    );
  };

  const navBarMobileRendering = () => {
    return (
      <>
        {topicsMobile()}
        {/* hamburger in mobile*/}
        <div className={'md:hidden flex flex-1 justify-end'}>
          {system.sideBarMobileOpen ? (
            <GrClose
              className={'md:hidden block w-10 h-10 p-2 cursor-pointer'}
              onClick={() => {
                closeMobileMenu();
              }}
            />
          ) : (
            <HiOutlineMenuAlt3
              className={'md:hidden block w-10 h-10 p-2 cursor-pointer'}
              onClick={() => {
                openMobileMenu();
              }}
            />
          )}
        </div>
        {/* side bar in mobile */}
        {sideBarXMobile()}
      </>
    );
  };

  const logoRendering = () => {
    return (
      <div
        className={'flex flex-1 justify-start items-center pl-10'}
        onClick={() => {
          logoClick();
        }}
      >
        <img src={logoImage} className="h-14" alt="inweb3 Logo" />
      </div>
    );
  };
  return (
    <nav
      className={
        'flex flex-3 justify-between items-center bg-gray-700 py-4 z-20 h-20 w-screen'
      }
    >
      {logoRendering()}
      {/* topics */}
      {topicsLaptop()}
      {navBarMobileRendering()}
      {renderUserPart()}
    </nav>
  );
}

export default NavBar;
