import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

export const emailValidation = yup
  .string()
  .label('Email')
  .email('the email is wrong')
  .required('please enter your email');

export const passwordValidation = yup
  .string()
  .required('please enter your password')
  .min(8, 'password minimum length is 8');

export const textWithMinValidation = (
  label: string,
  min: number,
  max: number,
) =>
  yup
    .string()
    .label(label)
    .required('username is required')
    .min(min, `should be minimum ${min} character length`)
    .max(max, `should be maximum ${max} character length`);

export const confirmPasswordValidation = (password: string) =>
  yup
    .string()
    .label('confirm Password')
    .required('confiramtion password is required')
    .oneOf(
      [yup.ref(password || 'password'), null],
      'there is mismatch between the two passwords',
    );

export const boolCheckValidation = yup
  .bool()
  .oneOf([true], 'Field must be checked');
