import React from 'react';
import { Button, Sidebar } from 'flowbite-react';
import { HiLogout } from 'react-icons/hi';
import {
  openLoginModal,
  toggleSideBarOpenMobile,
} from '@inweb3/core/src/redux/system/system.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { sleep } from '@inweb3/core/src/utils/general';
import { SideBarItemType, SideBarProps } from './sidebar.type';
import { mySelectors } from '../../redux/selectors';
import { endpoint } from '../../navigation/endpoint';
import { devSideBarItem } from './sidebaritem/dev_side_bar_item';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { eventBusFront, eventBusFrontType } from '../../../configs/event_bus';
import { blogItemSideBar } from './sidebaritem/blogitemsidebar';
import { jobBoardItemSideBar } from './sidebaritem/job_board_item_side_bar';

function SideBar({ className }: SideBarProps) {
  const user = mySelectors().user;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = () => {
    console.log('login');
    dispatch(openLoginModal(true));
  };

  const signup = () => {
    console.log('signup');
    dispatch(toggleSideBarOpenMobile(false));
    dispatch(
      navigate(endpoint.signup, {
        replace: true,
      }),
    );
  };

  const navigateTo = (endpoint: string) => {
    navigate(endpoint, {
      replace: true,
    });
  };

  const clickOnSideBarItemNoPro = (value: SideBarItemType) => {
    console.log('user is logged ' + user.isLogged);

    if (value.isLogged) {
      if (user.isLogged) {
        navigateTo(value.endpoint);
      } else {
        dispatch(openLoginModal(true));
      }
    } else {
      navigateTo(value.endpoint);
    }
  };

  const renderSideBarItems = () => {
    const pathname = window.location.pathname;
    const topic = pathname.split('/')[1];
    switch (topic) {
      case endpoint.blogs.topic:
        return sideBarItems(blogItemSideBar);
      case endpoint.jobboard.topic:
        return sideBarItems(jobBoardItemSideBar);
      case endpoint.devs.topic:
        return sideBarItems(devSideBarItem);
      default:
        return sideBarItems(blogItemSideBar);
    }
  };

  const sideBarItems = (itemtemSideBar: SideBarItemType[]) => {
    return itemtemSideBar.map((value) => {
      const active = value.endpoint === window.location.pathname;
      return (
        <div
          key={value.indexSousTopic}
          onClick={() => {
            clickOnSideBarItemNoPro(value);
          }}
        >
          <Sidebar.Item
            icon={value.icon}
            className={`${textWhiteFontSemibold} hover:text-black cursor-pointer ${
              active ? 'text-black' : ''
            }`}
            active={active}
          >
            <span>{value.title}</span>
          </Sidebar.Item>
        </div>
      );
    });
  };

  return (
    <Sidebar className={` flex flex-col bg-gray-900 h-full ${className}`}>
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          {renderSideBarItems()}
          <div className={'flex-1 h-auto'}></div>
          {user.isLogged ? (
            <div
              onClick={() => {
                toast.error('Good Bye');
                sleep(1000).then(() => {
                  eventBusFront.dispatch(eventBusFrontType.LOGOUT, { details: {} });
                });
              }}
            >
              <Sidebar.Item
                icon={() => <HiLogout />}
                className={
                  textWhiteFontSemibold + ' hover:text-black cursor-pointer'
                }
              >
                Log out
              </Sidebar.Item>
            </div>
          ) : null}
        </Sidebar.ItemGroup>
      </Sidebar.Items>
      <div className={'flex flex-1'}></div>
      {user.isLogged ? null : (
        <div className={'md:hidden flex flex-row w-full'}>
          <Button
            className={
              'px-3 mr-3 focus:ring-0 border-blue-600 bg-transparent w-full'
            }
            onClick={login}
          >
            Login
          </Button>
          <Button
            className={'px-3 border-0 focus:ring-0 border-blue-600 w-full'}
            color={'blue'}
            onClick={signup}
          >
            Signup
          </Button>
        </div>
      )}
    </Sidebar>
  );
}

export default SideBar;
