import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export interface InputProps {
  type: string;
  id: string;
  className: string;
  classNameLabel: string;
  placeholder: string;
  labelTitle: string;
  errorMessage: string | undefined;
  register: UseFormRegisterReturn;
}

function InputInnoom({
  type,
  id,
  className,
  placeholder,
  labelTitle,
  errorMessage,
  register,
  classNameLabel,
}: InputProps) {
  return (
    <div>
      <label
        htmlFor="email"
        className={` ${classNameLabel} block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
      >
        {labelTitle}
      </label>
      <input
        type={type}
        id={id}
        className={`${className} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
        placeholder={placeholder}
        {...register}
      />
      <p
        className={
          'flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'
        }
      >
        {errorMessage}
      </p>
    </div>
  );
}

export default InputInnoom;
