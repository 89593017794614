import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Footer } from 'flowbite-react';
import { endpoint } from '../../navigation/endpoint';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { useDispatch } from 'react-redux';
import {
  openLoginModal,
  toggleContactModalOpen,
  toggleSideBarOpenMobile,
} from '@inweb3/core/src/redux/system/system.slice';
import { mySelectors } from '../../redux/selectors';
import { eventBusFront, eventBusFrontType } from '../../../configs/event_bus';
import { getTopic, setTopic } from '@inweb3/core/src/utils/authentication';
import { updateActiveTopic } from '../../redux/topics/topic.slice';
import NavBar from '@inweb3/component/src/common_layouts/navbar/NavBar';
import SideBar from '../sidebar/SideBar';
import { topicsForNavBar } from '../../navigation/topics';

function Main() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = mySelectors().user;
  const system = mySelectors().system;

  return (
    <div className={'bg-gray-900 flex flex-col relative items-stretch'}>
      <div className={'flex flex-col'}>
        {
          <NavBar
            user={user}
            changeTopicClick={(index, endpoint) => {
              setTopic(index);
              dispatch(updateActiveTopic(index));
              navigate(endpoint, {
                replace: true,
              });
            }}
            closeMobileMenu={() => {
              dispatch(toggleSideBarOpenMobile(!system.sideBarMobileOpen));
            }}
            logoClick={() => {
              navigate(endpoint.blogs.home, {
                replace: true,
              });
            }}
            logoImage={'/inweb3_500x500.png'}
            logoutClick={() => {
              eventBusFront.dispatch(eventBusFrontType.LOGOUT, { detail: {} });
            }}
            currentTopicIndex={getTopic()}
            openMobileMenu={() => {
              dispatch(toggleSideBarOpenMobile(!system.sideBarMobileOpen));
            }}
            system={system}
            topicOn={true}
            login={() => {
              console.log('login');
              dispatch(openLoginModal(true));
            }}
            signup={() => {
              console.log('signup');
              dispatch(
                navigate(endpoint.signup, {
                  replace: true,
                }),
              );
            }}
            componentSideBar={
              <SideBar className={'bg-gray-700 bg-opacity-0'} inNav={true} />
            }
            topicsForNavBar={topicsForNavBar}
          ></NavBar>
        }
        <Outlet />
        <Footer className={'bg-gray-900 flex justify-between flex-col md:flex md:flex-row md:h-10 h-30'}>
          <a
            href="https://twitter.com/IslamNeddar"
            target={'_blank'}
            rel="noreferrer"
            className={`${textWhiteFontSemibold} pr-4`}
          >
            By @islam
          </a>
          <Footer.Copyright href="#" by="Innoom Studio™" year={2022} />
          <Footer.LinkGroup className="mt-3 flex-wrap items-center text-sm sm:mt-0 ">
            <Footer.Link href={endpoint.conditionandterms}>
              Term of use
            </Footer.Link>
            <Footer.Link href={endpoint.privacyPolicy}>
              Privacy Policy
            </Footer.Link>
            <p
              className={'cursor-pointer'}
              onClick={() => {
                dispatch(toggleContactModalOpen(true));
              }}
            >
              Contact
            </p>
          </Footer.LinkGroup>
        </Footer>
      </div>
    </div>
  );
}

export default Main;
