import React, { useState } from 'react';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { emailValidation } from '@inweb3/core/src/validations';
import { requestServiceError } from '@inweb3/core/src/utils/general';
import { userService } from '@inweb3/core/src/services/user.service';
import { toast } from 'react-hot-toast';
import ContainerBody from '@inweb3/component/src/common_layouts/ContainerBody';
import InputInnoom from '@inweb3/component/src/component/InputInnoom';
import ButtonInnoom from '@inweb3/component/src/component/ButtonInnoom';

export const emailSubscriptionValidation = yup.object({
  email: emailValidation,
});

export interface EmailSubscriptionType {
  email: string;
}

function PodcastContainer() {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EmailSubscriptionType>({
    resolver: yupResolver(emailSubscriptionValidation),
  });

  const onSubmit: SubmitHandler<EmailSubscriptionType> = async (data) => {
    await subscribe(data);
  };

  const subscribe = async (inputs: EmailSubscriptionType) => {
    setLoading(true);
    try {
      await userService.subscribe(inputs.email);
      toast.success('thank you, you are now one of the community inweb3');
    } catch (err) {
      console.log(err);
      requestServiceError(err);
    }
    setLoading(false);
  };

  return (
    <ContainerBody applyOverflow={true}>
      <div className={'flex flex-1 items-center'}>
        <div className={'flex flex-row w-full'}>
          <div className={'flex flex-1 flex-col md:pl-10'}>
            <h1 className={`${textWhiteFontSemibold} text-3xl py-5`}>
              Let s make what you hear valuable to go to the next level in web3
            </h1>
            <div>
              <p className={`${textWhiteFontSemibold} font-light`}>
                Find More Than +200 Podcasts with more than +3500 episodes about
                web3{' '}
              </p>
              <p className={`${textWhiteFontSemibold} font-light`}>
                Want to hear from us when we will add this feature? signup for
                our newsletter and we will email you every time we release a new
                batch of features
              </p>
              <p className={`${textWhiteFontSemibold} font-light`}>
                By subscribing, you agree with Revue’s{' '}
                <a
                  href={'https://www.getrevue.co/terms'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href={'https://www.getrevue.co/terms'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
            <div className={'py-5'}>
              <form
                className="flex flex-col gap-4 w-full"
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputInnoom
                  className={''}
                  classNameLabel={'text-white'}
                  id={'email_podcast_sub'}
                  type={'text'}
                  placeholder={'example@email.com'}
                  labelTitle={'Email'}
                  errorMessage={errors.email?.message}
                  register={register('email')}
                ></InputInnoom>
                <ButtonInnoom
                  disabled={loading}
                  title={'Subscribe'}
                  loading={loading}
                  click={() => {
                    console.log('button signup');
                  }}
                />
              </form>
            </div>
          </div>
          <div className={'md:flex-1 md:flex '}></div>
        </div>
      </div>
    </ContainerBody>
  );
}

export default PodcastContainer;
