import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BlogsWithPaginationResponse } from '@inweb3/core/src/types/api/blog';
import Masonry from 'react-masonry-css';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { useDispatch } from 'react-redux';
import { openLoginModal } from '@inweb3/core/src/redux/system/system.slice';
import { UserState } from '@inweb3/core/src/types/user/user.type';
import BlogCard from './BlogCard';
import './mansory.css';

export interface BlogListProps {
  scrollId: string;
  blogs: BlogsWithPaginationResponse;
  hasMore: boolean;
  fetchBlogs: () => any;
  user: UserState;
  logout: () => void;
}

function BlogList({
  scrollId,
  blogs,
  fetchBlogs,
  hasMore,
  user,
  logout,
}: BlogListProps) {
  const dispatch = useDispatch();

  return (
    <>
      <InfiniteScroll
        dataLength={blogs.blogs.length}
        next={fetchBlogs}
        hasMore={hasMore}
        loader={
          <h4 className={'text-center ' + textWhiteFontSemibold}>
            {user.isLogged ? (
              <span> Loading...</span>
            ) : (
              <span>
                you have to login by{' '}
                <span
                  className={'text-blue-300 cursor-pointer'}
                  onClick={() => {
                    dispatch(openLoginModal(true));
                  }}
                >
                  clicking here
                </span>{' '}
                so you can scroll more
              </span>
            )}
          </h4>
        }
        endMessage={<p style={{ textAlign: 'center' }}></p>}
        scrollableTarget={scrollId}
        scrollThreshold={0.5}
      >
        <Masonry
          breakpointCols={{
            2000: 4,
            1400: 3,
            1024: 2,
            768: 2,
            425: 1,
          }}
          className="my-masonry-grid px-5 md:px-20"
          columnClassName="my-masonry-grid_column "
        >
          {blogs.blogs.map((blog) => {
            return (
              <>
                <BlogCard
                  blog={blog}
                  user={user}
                  logout={() => {
                    logout();
                  }}
                />
              </>
            );
          })}
        </Masonry>
      </InfiniteScroll>
    </>
  );
}

export default BlogList;
