import { Label } from 'flowbite-react';
import React, { useState } from 'react';
import { endpoint } from '../../../navigation/endpoint';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import {
  boolCheckValidation,
  confirmPasswordValidation,
  emailValidation,
  passwordValidation,
  textWithMinValidation,
} from '@inweb3/core/src/validations';
import { getUserJobsFromLocalStorage } from '@inweb3/core/src/utils/authentication';
import { GetUserJobResponse } from '@inweb3/core/src/types/api/userjob';
import { userService } from '@inweb3/core/src/services/user.service';
import { requestService } from '@inweb3/core/src/utils/request';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ErrorReturnFormInnoom from '@inweb3/component/src/component/ErrorReturnFormInnoom';
import InputInnoom from '@inweb3/component/src/component/InputInnoom';
import SelectInnoom from '@inweb3/component/src/component/SelectInnoom';
import CheckBoxInnoom from '@inweb3/component/src/component/CheckBoxInnoom';
import ButtonInnoom from '@inweb3/component/src/component/ButtonInnoom';

export interface SignupInputs {
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  acceptTermCondition: boolean;
  userJob: string;
}

const signupValidation = yup.object({
  username: textWithMinValidation('Username', 6, 30),
  email: emailValidation,
  password: passwordValidation,
  passwordConfirmation: confirmPasswordValidation('password'),
  acceptTermCondition: boolCheckValidation,
  userJob: yup.string().required('this field is required'),
});

function FormSignup() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const jobs: GetUserJobResponse = getUserJobsFromLocalStorage();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SignupInputs>({
    resolver: yupResolver(signupValidation),
  });

  const onSubmit: SubmitHandler<SignupInputs> = async (data) => {
    await signup(data);
  };

  const signup = async (inputs: SignupInputs) => {
    console.log(inputs);
    setIsLoading(true);
    try {
      await userService.signup(inputs);
      reset({
        email: '',
        password: '',
        username: '',
        acceptTermCondition: false,
        passwordConfirmation: '',
      });
      toast.success(
        'signup with success, validate your email to have access to all features',
      );

      navigate(endpoint.blogs.home, { replace: true });
    } catch (err) {
      const data = requestService.getMessageFromErrorAxios(err);
      console.log(data.message);
      toast.error(data.message);
      setError(data.message);
    }
    setIsLoading(false);
  };
  return (
    <div className={'flex flex-col'}>
      <ErrorReturnFormInnoom error={error} />
      <form
        className="flex flex-col gap-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputInnoom
          className={''}
          classNameLabel={'text-white'}
          id={'username_signup'}
          type={'text'}
          placeholder={'cult dao'}
          labelTitle={'Username'}
          errorMessage={errors.username?.message}
          register={register('username')}
        ></InputInnoom>
        <InputInnoom
          className={''}
          classNameLabel={'text-white'}
          id={'email_signup'}
          type={'text'}
          placeholder={'example@email.com'}
          labelTitle={'Email'}
          errorMessage={errors.email?.message}
          register={register('email')}
        ></InputInnoom>
        <InputInnoom
          className={''}
          classNameLabel={'text-white'}
          id={'password_signup'}
          type={'password'}
          placeholder={'********'}
          labelTitle={'Password'}
          errorMessage={errors.password?.message}
          register={register('password')}
        ></InputInnoom>
        <InputInnoom
          className={''}
          classNameLabel={'text-white'}
          id={'password_confirm_signup'}
          type={'password'}
          placeholder={'********'}
          labelTitle={'Confirm Password'}
          errorMessage={errors.passwordConfirmation?.message}
          register={register('passwordConfirmation')}
        ></InputInnoom>
        <div id="select">
          <Label className="mb-2 block text-white">Select your job</Label>
          <SelectInnoom register={register('userJob')}>
            <>
              {jobs.userJobs.map((jobuser) => (
                <option key={jobuser.userJobId} value={jobuser.title} selected>
                  {jobuser.title}
                </option>
              ))}
            </>
          </SelectInnoom>
          <p
            className={
              'flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'
            }
          >
            {errors.userJob?.message}
          </p>
        </div>
        <CheckBoxInnoom
          id={'accept_term_signup'}
          register={register('acceptTermCondition')}
          classNameInput={''}
          classNameText={'text-white'}
          errorMessage={errors.acceptTermCondition?.message}
        >
          <>
            I agree with the{' '}
            <a
              href={endpoint.conditionandterms}
              className="text-blue-600 hover:underline dark:text-blue-500"
            >
              terms and conditions
            </a>
          </>
        </CheckBoxInnoom>
        <ButtonInnoom
          disabled={isLoading}
          title={'create account'}
          loading={isLoading}
          click={() => {
            console.log('button signup');
          }}
        />
      </form>
    </div>
  );
}

export default FormSignup;
