import React, { useState } from 'react';
import moment from 'moment';
import { BlogAPI } from '@inweb3/core/src/types/api/blog';
import { Tooltip } from 'flowbite-react';
import { isAuth } from '@inweb3/core/src/utils/authentication';
import { requestServiceError } from '@inweb3/core/src/utils/general';
import { userService } from '@inweb3/core/src/services/user.service';
import { useDispatch } from 'react-redux';
import { HiOutlineBookmark,HiOutlineLightBulb } from 'react-icons/hi';
import { openLoginModal } from '@inweb3/core/src/redux/system/system.slice';
import { UserState } from '@inweb3/core/src/types/user/user.type';

interface BlogCardProps {
  blog: BlogAPI;
  user: UserState;
  logout: () => void;
}

function BlogCard({ blog, user, logout }: BlogCardProps) {
  const dispatch = useDispatch();
  const [likedBlog, setLikedBlog] = useState<boolean>(blog.liked);
  const [numberLike, setNumberLikes] = useState<number>(
    blog.likesTotal !== undefined ? (blog.likesTotal as number) : 0,
  );
  const [bookmarkedBlog, setBookmarkedBlog] = useState<boolean>(
    blog.bookmarked,
  );

  const likeBlog = async () => {
    const currentLike = likedBlog;
    let currentNumberOfLike = numberLike as number;
    try {
      if (currentLike) {
        setNumberLikes(--currentNumberOfLike);
      } else {
        setNumberLikes(++currentNumberOfLike);
      }
      setLikedBlog(!currentLike);
      const token = isAuth();
      if (token === null) {
        //eventBus.dispatch(eventBusType.LOGOUT, { detail: {} });
        logout();
        return;
      }
      await userService.likeBlog(blog, token);
    } catch (err) {
      setLikedBlog(currentLike);
      setNumberLikes(currentNumberOfLike);
      requestServiceError(err);
    }
  };

  const bookmarkBlog = async () => {
    const currentBookmark = bookmarkedBlog;
    try {
      setBookmarkedBlog(!currentBookmark);
      const token = isAuth();
      if (token === null) {
        //eventBus.dispatch(eventBusType.LOGOUT, { detail: {} });
        logout();
        return;
      }
      await userService.bookmarkBlog(blog, token);
    } catch (err) {
      setBookmarkedBlog(currentBookmark);
      requestServiceError(err);
    }
  };

  return (
    <>
      <div
        className={
          'bg-white rounded-lg shadow-md z-10 bg-gray-800 drop-shadow-md'
        }
      >
        <div className={'w-full flex h-full flex-col'}>
          {blog.image ? (
            <div
              className={
                'h-40 relative overflow-hidden w-full rounded-lg cursor-pointer'
              }
            >
              <img
                className={
                  'absolute block inset-0 w-full h-full m-auto object-cover'
                }
                src={blog.image || ''}
              />
            </div>
          ) : null}
          <div className={'flex flex-col'}>
            <div className={'flex mt-2 mx-3 flex-row justify-between'}>
              <div className={'flex flex-row justify-around items-center'}>
                <Tooltip content={blog.sourceBlog.name}>
                  <img
                    className={'rounded-full w-7 h-7 object-cover'}
                    src={blog.sourceBlog.image}
                  />
                </Tooltip>
                <p className={'text-white font-medium text-sm pl-2'}>
                  {moment(blog.dateCreationBlog).format('DD/MM/YYYY HH:mm')}
                </p>
              </div>
            </div>
            <div className={'mx-3 my-2 cursor-pointer'}>
              <a
                className={'text-white font-bold'}
                href={blog.permalink}
                target={'_blank'}
                rel="noreferrer"
              >
                {blog.title}
              </a>
            </div>
          </div>
          <>
            <div className={'flex-1'} />
            <div className={'flex flex-row justify-around py-3'}>
              <div
                className={
                  'flex-1 flex justify-center items-center cursor-pointer '
                }
              >
                <div
                  className={`${likedBlog ? 'scale-125' : ''}`}
                  onClick={() => {
                    if (user.isLogged) {
                      likeBlog();
                    } else {
                      dispatch(openLoginModal(true));
                    }
                  }}
                >
                  <HiOutlineLightBulb
                    color={"white"}
                    fill={`${likedBlog ? 'blue' : 'none'}`}
                    className={' h-6 w-6'}
                  />
                </div>
                <p className={'pl-2 text-white font-semibold'}>{numberLike}</p>
              </div>
              {/*<div className={'flex-1 flex justify-center cursor-pointer'}>
                  <CommentIcon />
                </div>*/}
              <div
                className={
                  'flex-1 flex justify-center items-center cursor-pointer'
                }
              >
                <div
                  className={`${bookmarkedBlog ? 'scale-100' : ''}`}
                  onClick={() => {
                    if (user.isLogged) {
                      bookmarkBlog();
                    } else {
                      dispatch(openLoginModal(true));
                    }
                  }}
                >
                  <HiOutlineBookmark
                    fill={`${bookmarkedBlog ? 'white' : 'none'}`}
                    className={' text-white h-5 w-5'}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default BlogCard;
