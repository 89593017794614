import React from 'react';
import FormSignup from './FormSignup';
import './Signup.css';
import ContainerBody from '@inweb3/component/src/common_layouts/ContainerBody';

function SignupPage() {
  return (
    <ContainerBody applyOverflow={true}>
      <div className={'flex w-full pt-10 pb-10'}>
        <div className={'w-full md:mr-60 md:ml-60'}>
          <div className={'flex flex-col items-center justify-center'}>
            <h3 className="mb-4 text-xl font-medium text-white dark:text-white">
              Sign un to inWeb3 Community
            </h3>
          </div>
          <FormSignup></FormSignup>
        </div>
      </div>
    </ContainerBody>
  );
}

export default SignupPage;
