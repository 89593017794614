import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  emailValidation,
  passwordValidation,
} from '@inweb3/core/src/validations';
import { userService } from '@inweb3/core/src/services/user.service';
import { requestService } from '@inweb3/core/src/utils/request';
import { setToken } from '@inweb3/core/src/utils/authentication';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { openLoginModal } from '@inweb3/core/src/redux/system/system.slice';
import { updateLoginSuccess } from '@inweb3/core/src/redux/user/user.slice';
import ToastErrorDefault from '../../component/ToastErrorDefault';
import InputInnoom from '../../component/InputInnoom';
import TextSimple from '../../component/TextSimple';
import CheckBoxInnoom from '../../component/CheckBoxInnoom';
import LoadingButtonInnoom from '../../component/LoadingButtonInnoom';

const loginValidation = yup.object({
  email: emailValidation,
  password: passwordValidation,
});

interface LoginInputs {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface FormLoginProps {
  passToSignupProps: () => void;
  lostPasswordClick: () => void;
}

function FormLogin({ passToSignupProps, lostPasswordClick }: FormLoginProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>({
    resolver: yupResolver(loginValidation),
  });

  const login = async (dataInput: LoginInputs) => {
    try {
      setIsLoading(true);
      const { data } = await userService.login(
        dataInput.email,
        dataInput.password,
      );
      console.log(data);
      setToken(data.token);
      dispatch(openLoginModal(false));
      dispatch(
        updateLoginSuccess({
          email: data.email,
          username: data.username,
          isLogged: true,
        }),
      );
      toast.success('logged success');
      window.location.reload();
    } catch (err: any) {
      const data = requestService.getMessageFromErrorAxios(err);
      console.log(data.message);
      setError(data.message);
    }
    setIsLoading(false);
  };

  const onSubmit: SubmitHandler<LoginInputs> = async (data) => {
    await login(data);
  };

  const passToSignup = () => {
    passToSignupProps();
  };
  return (
    <>
      {error !== null ? (
        <div className={'w-full'}>
          <ToastErrorDefault message={error} />
        </div>
      ) : null}
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <InputInnoom
          className={''}
          classNameLabel={''}
          id={'email'}
          type={'text'}
          placeholder={'example@email.com'}
          labelTitle={'Email'}
          errorMessage={errors.email?.message}
          register={register('email')}
        ></InputInnoom>
        <InputInnoom
          className={''}
          classNameLabel={''}
          id={'password'}
          type={`${showPassword ? 'text' : 'password'}`}
          placeholder={'********'}
          labelTitle={'Password'}
          errorMessage={errors.password?.message}
          register={register('password')}
        ></InputInnoom>
        <div className={'flex w-full justify-end'}>
          <TextSimple
            text={`${showPassword ? 'hide Password' : 'show Password'}`}
            className={'cursor-pointer'}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
        </div>
        <div className="flex justify-between">
          <div className="flex items-start">
            <CheckBoxInnoom
              id={'remember_login'}
              register={register('rememberMe')}
              classNameInput={''}
              classNameText={''}
              errorMessage={''}
            >
              <>RememberMe</>
            </CheckBoxInnoom>
          </div>
          <a
            onClick={() => {
              /**/
              lostPasswordClick();
            }}
            target="_blank"
            rel="noreferrer"
            className="text-sm text-blue-700 hover:underline dark:text-blue-500"
          >
            Lost Password?
          </a>
        </div>
        <button
          type="submit"
          className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {isLoading ? <LoadingButtonInnoom /> : <>Login</>}
        </button>
        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
          Not registered?{' '}
          <a
            onClick={() => {
              passToSignup();
            }}
            className="text-blue-700 hover:underline dark:text-blue-500 cursor-pointer"
          >
            Create account
          </a>
        </div>
      </form>
    </>
  );
}

export default FormLogin;
