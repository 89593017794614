import { API_URL } from '@inweb3/frontend/src/configs/config';
import axios from 'axios';
import { SignupInputs } from '@inweb3/frontend/src/lib/pages/auth/signup/FormSignup';
import { BlogAPI } from '../types/api/blog';
import { headerTokenUser } from './common';

const endpointUser = `${API_URL}/user`;

const signup = async (inputs: SignupInputs) => {
  const { data, status } = await axios.post(endpointUser + '/signup', {
    email: inputs.email,
    password: inputs.password,
    username: inputs.username,
    jobUser: inputs.userJob,
  });
  console.log(data);
  return { data, status };
};

const login = async (email: string, password: string) => {
  const { data, status } = await axios.post(endpointUser + '/login', {
    email: email,
    password: password,
  });
  console.log(data);
  return { data, status };
};

const get = async (token: string) => {
  const { data, status } = await axios.get(endpointUser + '/info', {
    headers: {
      'inweb3-token': token,
    },
  });
  console.log(data);
  return { data, status };
};

const demandeChangePassword = async (email: string) => {
  const { data, status } = await axios.post(
    endpointUser + '/demande_change_password',
    {
      email: email,
    },
  );
  console.log(data);
  return { data, status };
};

const confirmEmail = async (token: string) => {
  const { data, status } = await axios.get(
    `${endpointUser}/signup_confirm?token=${token}`,
  );
  console.log(data);
  return { data, status };
};

const resetPassword = async (password: string, token: string) => {
  const { data, status } = await axios.post(
    `${endpointUser}/reset_password?token=${token}`,
    {
      password: password,
    },
  );
  console.log(data);
  return { data, status };
};

const likeBlog = async (blog: BlogAPI, token: string) => {
  const { data, status } = await axios.post(
    `${endpointUser}/blog/like`,
    {
      blogId: blog.blogId,
    },
    {
      ...headerTokenUser(token),
    },
  );
  console.log(data);
  return { data, status };
};

const bookmarkBlog = async (blog: BlogAPI, token: string) => {
  const { data, status } = await axios.post(
    `${endpointUser}/blog/bookmark`,
    {
      blogId: blog.blogId,
    },
    {
      ...headerTokenUser(token),
    },
  );
  console.log(data);
  return { data, status };
};

const subscribe = async (email: string) => {
  const { data, status } = await axios.post(endpointUser + '/waitlist', {
    email: email,
  });
  console.log(data);
  return { data, status };
};

export const userService = {
  signup,
  login,
  get,
  demandeChangePassword,
  confirmEmail,
  resetPassword,
  likeBlog,
  bookmarkBlog,
  subscribe,
};
