import { endpoint } from '../../navigation/endpoint';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TopicState {
  activeTopic: number;
}

const initialState: TopicState = {
  activeTopic: 0,
};

export const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    updateActiveTopic: (state, action: PayloadAction<number>) => {
      state.activeTopic = action.payload;
    },
  },
});

export const {updateActiveTopic} = topicSlice.actions;

export default topicSlice.reducer;
