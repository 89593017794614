import React from 'react';
import {
  textWhieFontLight,
  textWhiteFontSemibold,
} from '@inweb3/core/src/styles/theme';
import ContainerBody from '@inweb3/component/src/common_layouts/ContainerBody';

function PrivacyPolicy() {
  return (
    <ContainerBody applyOverflow={true}>
      <div className={'p-5'}>
        <h1 className={textWhiteFontSemibold + ' text-3xl mb-4'}>
          Privacy Policy
        </h1>
        <p className={textWhieFontLight}>
          inWeb3 (us, we, or our) operates inweb3.co (the Site). This page
          informs you of our policies regarding the collection, use and
          disclosure of Personal Information we receive from users of the Site.
        </p>
        <br />
        <p className={textWhieFontLight}>
          We use your Personal Information only for providing and improving the
          Site. By using the Site, you agree to the collection and use of
          information in accordance with this policy. Unless otherwise defined
          in this Privacy Policy, terms used in this Privacy Policy have the
          same meanings as in our Terms and Conditions, accessible at inweb3.co.
        </p>
        <br />
        <h2 className={textWhiteFontSemibold + ' text-2xl '}>
          Information Collection And Use
        </h2>
        <br />
        <p className={textWhieFontLight}>
          While using our Site, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to, your name, email address, postal address and phone
          number (Personal Information).
        </p>
        <br />
        <h2 className={textWhiteFontSemibold + ' text-2xl'}>Log Data</h2>
        <br />
        <p className={textWhieFontLight}>
          inweb3 will never share, sell or rent users portfolio data with any
          third parties.
        </p>
        <br />
        <h2 className={textWhiteFontSemibold + ' text-2xl '}>Cookies</h2>
        <p className={textWhieFontLight}>
          Cookies are files with small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          web site and stored on your computers hard drive. Like many sites, we
          use cookies to collect information. You can instruct your browser to
          refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some
          portions of our Site.
        </p>
        <br />
        <h2 className={textWhiteFontSemibold + ' text-2xl '}>Security</h2>
        <br />
        <p className={textWhieFontLight}>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage, is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>
        <br />
        <h2 className={textWhiteFontSemibold + ' text-2xl '}>
          Links To Other Sites
        </h2>
        <br />
        <p className={textWhieFontLight}>
          Our Site may contain links to other sites that are not operated by us.
          If you click on a third party link, you will be directed to that third
          partys site. We strongly advise you to review the Privacy Policy of
          every site you visit. inweb3 has no control over, and assumes no
          responsibility for, the content, privacy policies, or practices of any
          third party sites or services.
        </p>
        <br />
        <h2 className={textWhiteFontSemibold + ' text-2xl'}>
          Changes To This Privacy Policy
        </h2>
        <br />
        <p className={textWhieFontLight}>
          inweb3 may update this Privacy Policy from time to time. We will
          notify you of any changes by posting the new Privacy Policy on the
          Site. You are advised to review this Privacy Policy periodically for
          any changes.
        </p>
        <br />
        <h2 className={textWhiteFontSemibold + ' text-2xl'}>
          Account Deletion
        </h2>
        <br />
        <p className={textWhieFontLight}>
          To delete your account and all data associated to your user, please
          login and go to{' '}
          <span className={'text-red-800 font-bold cursor-pointer'}>
            Account Deletion
          </span>{' '}
          to initiate deletion process.
        </p>
        <br />
      </div>
    </ContainerBody>
  );
}

export default PrivacyPolicy;
