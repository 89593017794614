import React from 'react';

export interface NavItemProps {
  content: string;
  soon: boolean;
  index: number;
  currentTopicIndex: number;
  changeTopicClick: (index: number) => void;
}

function NavItem({
  content,
  soon,
  index,
  currentTopicIndex,
  changeTopicClick,
}: NavItemProps) {
  return (
    <li
      className="text-md font-semibold relative cursor-pointer"
      onClick={() => {
        changeTopicClick(index);
      }}
    >
      <p
        className={`${
          currentTopicIndex === index ? 'text-blue-500' : 'text-gray-500'
        }`}
      >
        {content}
        {soon ? (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
            soon
          </span>
        ) : null}
      </p>
    </li>
  );
}

export default NavItem;
