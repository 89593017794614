import { typeRessourceItemEnum } from '../typeressource';

export interface CourseTypes {
  index: number;
  name: string;
  website: string;
  price?: number;
  type: typeRessourceItemEnum;
  tags?: string[];
}

export const courses = [
  {
    index: 0,
    name: 'learnweb3',
    website: 'https://www.learnweb3.io/',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 1,
    name: 'Free Code Camp web3',
    website: 'https://www.freecodecamp.org/news/tag/web3/',
    price: 0,
    type: typeRessourceItemEnum.website,
  },

  {
    index: 5,
    name: 'ChainLink Oracles by ChainShot',
    website: 'https://www.chainshot.com/learn/chainlink',
    price: 0,
    type: typeRessourceItemEnum.website,
  },

  {
    index: 7,
    name: 'Learn everything about NFTs',
    website: 'https://metaschool.so/courses/learn-everything-about-nfts',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 8,
    name: 'Build on Solana',
    website: 'https://openquest.xyz/tracks/build-on-solana',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 11,
    name: 'A Full Course on Ethereum, Blockchain Development, Smart Contracts, and the EVM',
    website: 'ipwxYa-F1uY',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 12,
    name: 'Blockchain Python programming tutorial [FULL COURSE] Web3.py',
    website: 'pZSegEXtgAE',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 13,
    name: 'Master Blockchain Programming With JavaScript - Web3.js',
    website: 'kDo_MdyNJzI',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 15,
    name: 'Web3 Blockchain Fundamentals MOOC',
    website:
      'https://www.youtube.com/playlist?list=PLxVihxZC42nF_MCN9PTvZMIifRjx9cZ2J',
    price: 0,
    type: typeRessourceItemEnum.youtubePlaylist,
  },
  {
    index: 16,
    name: 'CryptoStartupSchool',
    website: 'https://a16z.com/crypto-startup-school/',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 17,
    name: 'Web3 learn to earn data cohort course by Network and dune',
    website:
      'https://ournetwork.mirror.xyz/gP16wLY-9BA1E_ZuOSv1EUAgYGfK9mELNza8cfgMWPQ',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 18,
    name: 'Polygon Course By OpenQuest',
    website: 'https://openquest.xyz/tracks/build-on-polygon',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 19,
    name: 'Introduction to Tokens by Defi Academy',
    website: 'https://www.defi-academy.com/courses/introduction-to-tokens',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 20,
    name: 'IPFS Course By Proto School',
    website: 'https://proto.school/course/ipfs',
    price: 0,
    type: typeRessourceItemEnum.website,
  },
  {
    index: 21,
    name: 'Morals Course By Morals Web3',
    website: 'https://proto.school/course/ipfs',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 22,
    name: 'Intro To Ethereum Programming by DApp University',
    website: 'itUrxH-rksc',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 23,
    name: 'Full Course on Polygon Development | Build Blockchain Apps on Polygon',
    website: 'pc1yLO56pbg',
    price: 0,
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 24,
    name: 'How To Build A Blockchain App Series',
    website:
      'https://www.youtube.com/playlist?list=PLS5SEs8ZftgW6kyVyD43dLXK0xpfiaJBJ',
    price: 0,
    type: typeRessourceItemEnum.youtubePlaylist,
  },
  {
    index: 25,
    name: 'DeFi Programming Course',
    website:
      'https://www.youtube.com/playlist?list=PLbbtODcOYIoGC8c5-gs0EYzpYVUPdmqO3',
    price: 0,
    type: typeRessourceItemEnum.youtubePlaylist,
  },
  {
    index: 26,
    name: 'Web3 Teacher Training Track',
    website: 'https://web3.courses/teacher-training-track/',
    price: 0,
    type: typeRessourceItemEnum.website,
    tags: ['Professional'],
  },
  {
    index: 27,
    name: 'Blockchain Professional Course for Engineer',
    website: 'https://www.blockdegree.org/blockchain-professional',
    type: typeRessourceItemEnum.website,
    tags: ['Professional'],
  },
  {
    index: 28,
    name: 'Ethereum 101 by Cadena',
    website: 'https://app.cadena.dev/course/ethereum-101/ZHjzLozd3mCsAcgMfeHE',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 29,
    name: 'NFT 101 by Cadena',
    website: 'https://app.cadena.dev/course/nft-101/Vd93yh7073UK5iMnc5KU',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 30,
    name: 'BlockChain 101 by Cadena',
    website:
      'https://app.cadena.dev/course/blockchain-101/jEDD59mBBeGKIpxXDnGl',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 31,
    name: 'Solidity 101: Introduction to smart contracts',
    website:
      'https://www.defi-academy.com/courses/introduction-to-smart-contracts',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 32,
    name: 'Ethers.js Library A Complete And Compact Library For Interacting With The Ethereum Blockchain.',
    website: 'https://www.chainshot.com/learn/ethers',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 33,
    name: 'Solidity Zero to Hero Course',
    website: 'https://www.codiesalert.com/courses/free-solidity-course/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 34,
    name: 'Discover Ethereum & Solidity by Tristan Edwards',
    website: 'https://www.ludu.co/course/ethereum',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 35,
    name: 'Introduction to Solidity',
    website: 'https://www.chainshot.com/learn/solidity',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 36,
    name: 'Solidity Tutorial by Eat the block',
    website:
      'https://www.youtube.com/playlist?list=PLbbtODcOYIoE0D6fschNU4rqtGFRpk3ea',
    type: typeRessourceItemEnum.youtubePlaylist,
  },
  {
    index: 37,
    name: 'MASTER Solidity for Blockchain Step-By-Step (Full Course) by Dapp University',
    website: 'YJ-D1RMI0T0',
    type: typeRessourceItemEnum.youtube,
  },
  {
    index: 38,
    name: 'Build on Ethereum by questbook',
    website: 'https://openquest.xyz/tracks/build-on-ethereum',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 39,
    name: 'ETHEREUM FUNDAMENTALS',
    website: 'https://learn.kba.ai/course/ethereum-fundamentals/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 40,
    name: 'Blockchain Foundation Program',
    website: 'https://learn.kba.ai/course/blockchain-foundation-program/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 41,
    name: 'Best Blockchain Guide for Beginners',
    website:
      'https://www.codiesalert.com/courses/best-blockchain-guide-for-beginners/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 42,
    name: 'Blockchain Essentials By Congnitive class',
    website: 'https://cognitiveclass.ai/courses/blockchain-course',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 43,
    name: 'MIT 15.S12 Blockchain and Money, Fall 2018',
    website:
      'https://www.youtube.com/playlist?list=PLUl4u3cNGP63UUkfL0onkxF6MYgVa04Fn',
    type: typeRessourceItemEnum.youtubePlaylist,
  },
  {
    index: 44,
    name: 'Blockchain Theory 101',
    website: 'https://www.udemy.com/course/blockchain-theory-101/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 45,
    name: 'Blockchain Technology By edx',
    website: 'https://www.edx.org/course/blockchain-technology',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 46,
    name: 'Get started with blockchain development By Microsoft',
    website:
      'https://docs.microsoft.com/en-us/learn/paths/ethereum-blockchain-development/',
    type: typeRessourceItemEnum.website,
  },
  {
    index: 47,
    name: 'Learn Blockchain: The COMPLETE beginner’s guide',
    website: '99pYGpTWcXM',
    type: typeRessourceItemEnum.youtube,
  },
];
