import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import App from './App';
import { store } from '../redux/store';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { API_URL } from "../../configs/config";

//const TRACKING_ID = "G-0HBDPY0VCE"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);

function Router() {
  console.log(API_URL)
  return (
    <Provider store={store}>
      <Fragment>
        <BrowserRouter>
          <div className={'w-screen'}>
            <Toaster
              containerStyle={{
                width: '100vw',
              }}
            />
          </div>
          <App />
        </BrowserRouter>
      </Fragment>
    </Provider>
  );
}

export default Router;
