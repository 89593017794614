import React, { useState } from 'react';
import * as yup from 'yup';
import {
  confirmPasswordValidation,
  passwordValidation,
} from '@inweb3/core/src/validations';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { userService } from '@inweb3/core/src/services/user.service';
import { requestService } from '@inweb3/core/src/utils/request';
import { toast } from 'react-hot-toast';
import { sleep } from '@inweb3/core/src/utils/general';
import { endpoint } from '../../../navigation/endpoint';
import InputInnoom from "@inweb3/component/src/component/InputInnoom";
import ErrorReturnFormInnoom from "@inweb3/component/src/component/ErrorReturnFormInnoom";
import ButtonInnoom from "@inweb3/component/src/component/ButtonInnoom";

export interface ResetPasswordFormProps {
  token: string;
}

export interface ResetPasswordFormInputs {
  password: string;
  passwordConfirmation: string;
}

const resetPasswordValidation = yup.object({
  password: passwordValidation,
  passwordConfirmation: confirmPasswordValidation('password'),
});

function ResetPasswordForm({ token }: ResetPasswordFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordFormInputs>({
    resolver: yupResolver(resetPasswordValidation),
  });

  const onSubmit: SubmitHandler<ResetPasswordFormInputs> = async (data) => {
    await resetPassword(data);
  };

  const resetPassword = async (input: ResetPasswordFormInputs) => {
    setIsLoading(true);
    try {
      const { data } = await userService.resetPassword(input.password, token);
      toast.success(data.message, {
        duration: 2000,
      });
      await sleep(2000);
      navigate('/' + endpoint.blogs.root, { replace: true });
    } catch (err) {
      const data = requestService.getMessageFromErrorAxios(err);
      console.log(data.message);
      toast.error(data.message);
      setError(data.message);
    }
    reset({
      password: '',
      passwordConfirmation: '',
    });
  };

  return (
    <div className={'flex flex-col'}>
      <ErrorReturnFormInnoom error={error} />
      <form
        className="flex flex-col gap-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputInnoom
          className={''}
          classNameLabel={'text-white'}
          id={'password_signup'}
          type={'password'}
          placeholder={'********'}
          labelTitle={'Password'}
          errorMessage={errors.password?.message}
          register={register('password')}
        ></InputInnoom>
        <InputInnoom
          className={''}
          classNameLabel={'text-white'}
          id={'password_confirm_signup'}
          type={'password'}
          placeholder={'********'}
          labelTitle={'Confirm Password'}
          errorMessage={errors.passwordConfirmation?.message}
          register={register('passwordConfirmation')}
        ></InputInnoom>
        <ButtonInnoom
          disabled={isLoading}
          title={'reset password'}
          loading={isLoading}
          click={() => {
            console.log('button signup');
          }}
        />
      </form>
    </div>
  );
}

export default ResetPasswordForm;
