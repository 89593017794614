import React from 'react';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { typeRessourceItemEnum } from '../typeressource';
import { Tooltip } from 'flowbite-react';
import { TutorialTypes } from './tutorials_data';
import { CgPlayList, CgWebsite } from 'react-icons/cg';
import { TbBrandYoutube } from 'react-icons/tb';

interface TutorialCardProps {
  tutorial: TutorialTypes;
}

function TutorialCard({ tutorial }: TutorialCardProps) {
  const renderIcons = (tutorial: TutorialTypes) => {
    switch (tutorial.type) {
      case typeRessourceItemEnum.website:
        return <CgWebsite />;
      case typeRessourceItemEnum.youtube:
        return <TbBrandYoutube />;
      case typeRessourceItemEnum.youtubePlaylist:
        return <CgPlayList />;
    }
  };
  return (
    <div
      className={
        'bg-white rounded-lg shadow-md z-10 bg-gray-800 drop-shadow-md h-full p-3 flex flex-col'
      }
    >
      <div>
        <p className={textWhiteFontSemibold}>{tutorial.name}</p>
      </div>

      <div className={'flex flex-1'}></div>
      <div className={'flex flex-row justify-between'}>
        <div></div>
        <div className={'flex items-center justify-center'}>
          <Tooltip content={tutorial.type}>{renderIcons(tutorial)}</Tooltip>
        </div>
      </div>
    </div>
  );
}

export default TutorialCard;
