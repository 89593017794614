import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { endpoint } from '../navigation/endpoint';
import {
  getUserJobsFromLocalStorage,
  isAuth,
  removeToken,
  setTopic,
  setUserJobsFromLocalStorage,
} from '@inweb3/core/src/utils/authentication';
import { requestService } from '@inweb3/core/src/utils/request';
import { userService } from '@inweb3/core/src/services/user.service';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { GetUserResponse } from '@inweb3/core/src/types/api/user';
import { mySelectors } from '../redux/selectors';
import { GetUserJobResponse } from '@inweb3/core/src/types/api/userjob';
import { userJobsService } from '@inweb3/core/src/services/userjobs.service';
import { Spinner } from 'flowbite-react';
import { eventBusFront, eventBusFrontType } from '../../configs/event_bus';
import routeWithSubRoutes, { RoutesEndpoint } from './Routes';
import { updateActiveTopic } from '../redux/topics/topic.slice';
import ContactUs from '../pages/contact/ContactUs';
import {
  logout,
  updateLoginSuccess,
} from '@inweb3/core/src/redux/user/user.slice';
import { openLoginModal } from '@inweb3/core/src/redux/system/system.slice';
import Login from '@inweb3/component/src/common_layouts/login/Login';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loaderPage, setLoaderPage] = useState<boolean>(true);
  const user = mySelectors().user;
  const system = mySelectors().system;

  useEffect(() => {
    // initialize topic
    dispatch(updateActiveTopic(renderTopicIndex()));
    setTopic(renderTopicIndex());

    // initialze jobs
    const userJobs = getUserJobsFromLocalStorage();
    if (userJobs === null) {
      getAllUserJobs();
    }

    //check token
    const token = isAuth();
    if (token !== null && user.username === null) {
      getUserAndLoggin(token);
    } else {
      console.log('no call to get user');
      setLoaderPage(false);
    }

    // subscribeto event to logout
    eventBusFront.on(eventBusFrontType.LOGOUT, () => {
      removeToken();
      dispatch(logout());
      /*navigate(endpoint.blogs.home, {
        replace: true,
      });*/
      window.location.reload();
    });

    return () => {
      eventBusFront.remove(eventBusFrontType.LOGOUT, () => {
        console.log('remove logout ');
      });
    };
  }, []);

  const getUserAndLoggin = async (token: string) => {
    try {
      const { data }: { data: GetUserResponse } = await userService.get(token);
      console.log(data);
      dispatch(
        updateLoginSuccess({
          email: data.email,
          username: data.username,
          isLogged: true,
        }),
      );
      setLoaderPage(false);
      //toast.success('logged success');
    } catch (err) {
      const response = requestService.getMessageFromErrorAxios(err);
      console.log(response.message);

      dispatch(logout());
      setLoaderPage(false);
      console.log('toast error');
      toast.error("we can't get user data, login again please");
    }
  };

  const getAllUserJobs = async () => {
    try {
      const { data }: { data: GetUserJobResponse[] } =
        await userJobsService.getAllUserJobs();
      setUserJobsFromLocalStorage(data);
    } catch (err) {
      const response = requestService.getMessageFromErrorAxios(err);
      console.log(response.message);
    }
  };

  // TODO reformat to be dynamics for the index number
  const renderTopicIndex = () => {
    const pathname = window.location.pathname;
    const topic = pathname.split('/')[1];
    switch (topic) {
      case endpoint.blogs.topic:
        return 0;
      case endpoint.devs.topic:
        return 2;
      case endpoint.podcasts.topic:
        return 1;
      default:
        return 0;
    }
  };

  if (loaderPage) {
    return (
      <div className={'h-screen'}>
        <div className="bg-gray-900 text-center h-full flex justify-center items-center">
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        {
          <Login
            lostPasswordClick={() => {
              dispatch(openLoginModal(false));
              navigate('/' + endpoint.newpassword, {
                replace: true,
              });
            }}
            system={system}
            closeModalProps={() => {
              dispatch(openLoginModal(false));
            }}
            passToSignupProps={() => {
              dispatch(openLoginModal(false));
              navigate(endpoint.signup, { replace: true });
            }}
          />
        }
        {<ContactUs />}
        <Routes>
          {routeWithSubRoutes(RoutesEndpoint)}
          <Route path="*" element={<Navigate to="/blogs/home" replace />} />
          <Route index element={<Navigate to="/blogs/home" replace />}></Route>
        </Routes>
      </>
    );
  }
}

export default App;
