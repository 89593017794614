export const typeRessourceItem = {
  website: 'webstie',
  youtube: 'youtube',
  youtubePlaylist: 'youtubePlaylist',
};

export enum typeRessourceItemEnum {
  website = 'webstie',
  youtube = 'youtube',
  youtubePlaylist = 'youtubePlaylist',
  amazone = 'amazone',
  github = 'github',
  medium = 'medium',
}
