import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { userService } from '@inweb3/core/src/services/user.service';
import { toast } from 'react-hot-toast';
import { requestServiceError } from '@inweb3/core/src/utils/general';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EmailSubscriptionType,
  emailSubscriptionValidation,
} from '../podcast/PodcastContainer';
import { HiFire } from 'react-icons/hi';
import ContainerBody from '@inweb3/component/src/common_layouts/ContainerBody';
import InputInnoom from '@inweb3/component/src/component/InputInnoom';
import ButtonInnoom from '@inweb3/component/src/component/ButtonInnoom';

function JobBoardContainer() {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EmailSubscriptionType>({
    resolver: yupResolver(emailSubscriptionValidation),
  });

  const onSubmit: SubmitHandler<EmailSubscriptionType> = async (data) => {
    await subscribe(data);
  };

  const subscribe = async (inputs: EmailSubscriptionType) => {
    setLoading(true);
    try {
      await userService.subscribe(inputs.email);
      toast.success('thank you, you are now one of the community inweb3');
    } catch (err) {
      console.log(err);
      requestServiceError(err);
    }
    setLoading(false);
  };

  return (
    <ContainerBody applyOverflow={true}>
      <div className={'flex flex-1 flex-col items-center'}>
        <div className={'w-full justify-start pt-5'}>
          <div
            className={
              'flex w-full items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400 duration-300 w-full '
            }
          >
            <div className="inline-flex w-full h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-blue-100 text-blue-500 dark:bg-blue-800 dark:text-blue-200">
              <HiFire className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-bold">
              you are a{' '}
              <span className={'text-xl text-red-800'}>web3 company</span> and
              you struggle to get{' '}
              <span className={'text-xl text-red-800'}>
                the best candidates
              </span>
              , contact us on
              <CopyToClipboard
                onCopy={() => {
                  toast.success('copied ! ');
                }}
                text={'neddar_islam@innoom.com'}
              >
                <span data-tip="click to copy" className={'cursor-pointer'}>
                  {' '}
                  neddar_islam@innoom.com{' '}
                </span>
              </CopyToClipboard>
              to get early access to{' '}
              <span className={'text-xl text-red-800'}>10 free job post</span>.
            </div>
          </div>
        </div>
        <div className={'flex flex-row w-full'}>
          <div className={'flex flex-1 flex-col md:pl-10'}>
            <h1 className={`${textWhiteFontSemibold} text-2xl py-5`}>
              Web3 a new <span>WORLD</span> to build
            </h1>
            <div>
              <p className={`${textWhiteFontSemibold} font-light`}>
                Find latest Web3 jobs around the world at companies working on
                the latest technologies of the web3 ecosystem
              </p>
              <p className={`${textWhiteFontSemibold} font-light`}>
                Want to hear from us when we will add this feature? signup for
                our newsletter and we will email every time we release a new
                batch of features
              </p>
              <p className={`${textWhiteFontSemibold} font-light`}>
                By subscribing, you agree with Revue’s{' '}
                <a
                  href={'https://www.getrevue.co/terms'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href={'https://www.getrevue.co/terms'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
            <div className={'py-5'}>
              <form
                className="flex flex-col gap-4 w-full"
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputInnoom
                  className={''}
                  classNameLabel={'text-white'}
                  id={'email_podcast_sub'}
                  type={'text'}
                  placeholder={'example@email.com'}
                  labelTitle={'Email'}
                  errorMessage={errors.email?.message}
                  register={register('email')}
                ></InputInnoom>
                <ButtonInnoom
                  disabled={loading}
                  title={'Subscribe'}
                  loading={loading}
                  click={() => {
                    console.log('button signup');
                  }}
                />
              </form>
            </div>
          </div>
          <div
            className={'hidden md:flex-1 md:flex justify-center items-center'}
          >
            <img
              src={'/landingpage_jobboard.jpg'}
              className={'rounded-2xl h-320'}
            />
          </div>
        </div>
      </div>
    </ContainerBody>
  );
}

export default JobBoardContainer;
