import React from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import { useParams } from 'react-router-dom';

function ResetPassword() {
  const { token } = useParams() as { token: string };

  return (
    <div className={'mt-7 sm:p-3 signup_container'}>
      <div className={'flex flex-col items-center justify-center'}>
        <h3 className="mb-4 text-xl font-medium text-white dark:text-white">
          Reset Your Password
        </h3>
      </div>
      <ResetPasswordForm token={token}></ResetPasswordForm>
    </div>
  );
}

export default ResetPassword;
