import { configureStore } from '@reduxjs/toolkit';
import userReducer from '@inweb3/core/src/redux/user/user.slice';
import systemReducer from '@inweb3/core/src/redux/system/system.slice';
import topicReducer from './topics/topic.slice';
import { ENV } from '../../configs/config';
import { createLogger } from 'redux-logger';

const middlewares = [];

if (ENV === `development`) {
  middlewares.push(createLogger({ collapsed: true }));
}

export const store = configureStore({
  reducer: {
    user: userReducer,
    system: systemReducer,
    topic: topicReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
