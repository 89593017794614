import { endpoint } from './endpoint';

export const topicsForNavBar = [
  {
    index: 0,
    key: 'blog',
    content: 'Blog',
    isActive: true,
    soon: false,
    endpoint: endpoint.blogs.home,
  },
  {
    index: 1,
    key: 'podcast',
    content: 'Podcast',
    isActive: false,
    soon: true,
    endpoint: endpoint.podcasts.home,
  },
  /*{
    index: 2,
    key: 'devhub',
    content: 'Dev3.0',
    isActive: false,
    soon: false,
    endpoint: endpoint.devs.courses,
  },*/
  /*{
   key: 'developer',
   content: 'Developer 3.0',
   isActive: false,
   soon: true,
   endpoint: '',
 },*/
  /*{
    key: 'nft',
    content: 'NFT',
    isActive: false,
    soon: true,
    endpoint: '',
  },*/
];
