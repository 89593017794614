import React, { useEffect, useState } from 'react';
import { HiSearch } from 'react-icons/hi';

import { BlogsWithPaginationResponse } from '@inweb3/core/src/types/api/blog';
import { useDispatch } from 'react-redux';
import { isAuth } from '@inweb3/core/src/utils/authentication';
import { requestServiceError } from '@inweb3/core/src/utils/general';
import { textWhiteFontSemibold } from '@inweb3/core/src/styles/theme';
import { Spinner } from 'flowbite-react';
import { openLoginModal } from '@inweb3/core/src/redux/system/system.slice';
import { BlogService } from '@inweb3/core/src/services/blog.service';
import {
  selectedOptionsForFilterBlog,
  SelectedOptionsForFilterBlogItemType,
} from '../../../../configs/data';
import { mySelectors } from '../../../redux/selectors';
import BlogList from '@inweb3/component/src/common_layouts/blog/BlogList';

function ListingBlogsContainer() {
  const paginationLimit = 15;
  const dispatch = useDispatch();
  const user = mySelectors().user;
  const [blogs, setBlogs] = useState<BlogsWithPaginationResponse>({
    blogs: [],
    totalBlogs: 0,
  });
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [shouldBeLoggedToSee, setShouldBeLoggedToSee] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<SelectedOptionsForFilterBlogItemType>(
      selectedOptionsForFilterBlog[0],
    );
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getBlogs() {
      await fetchBlogs();
    }

    getBlogs();
  }, [selectedOption, searchText]);

  const onChangeSelectedFilter = async (changed: any) => {
    setBlogs({
      blogs: [],
      totalBlogs: 0,
    });
    setHasMore(true);
    setOffset(0);
    setSelectedOption(changed);
  };

  const fetchBlogs = async () => {
    console.log('fetch blogs');
    setLoading(true);
    if (hasMore && !shouldBeLoggedToSee) {
      if (!user.isLogged && offset == 2 * paginationLimit) {
        setShouldBeLoggedToSee(true);
        dispatch(openLoginModal(true));
      }
      console.log(offset);
      console.log(offset + paginationLimit);
      try {
        const token = isAuth();
        const { data } =
          token === null
            ? await BlogService.getInstance().getAllBlogWithPagination(
                {
                  offset: offset,
                  limit: offset + paginationLimit,
                },
                selectedOption.value,
                searchText,
              )
            : await BlogService.getInstance().getAllBlogWithPaginationForAuthenticatedUser(
                {
                  offset: offset,
                  limit: offset + paginationLimit,
                },
                selectedOption.value,
                searchText,
                token,
              );
        const blogsToSave = {
          blogs: blogs.blogs.concat(data.blogs),
          totalBlogs: data.totalBlogs,
        };
        if (blogsToSave.blogs.length >= blogsToSave.totalBlogs) {
          setHasMore(false);
        } else {
          setOffset(offset + paginationLimit);
        }
        setBlogs(blogsToSave);
      } catch (err) {
        console.log(err);
        requestServiceError(err);
      }
    } else {
      console.log('dont has more');
    }
    setLoading(false);
  };

  const updateSearch = async () => {
    const searchFilterElement = document.getElementById(
      'search_blog',
    ) as HTMLInputElement;
    if (searchFilterElement.value !== searchText) {
      const searchFilter = searchFilterElement.value;
      setBlogs({
        blogs: [],
        totalBlogs: 0,
      });
      setHasMore(true);
      setOffset(0);
      setShouldBeLoggedToSee(false);
      setSearchText(searchFilter);
    }
  };

  const renderBlogList = () => {
    if (loading && blogs.blogs.length === 0) {
      return (
        <div className={'w-full flex justify-center items-center h-full'}>
          <Spinner className="mt-3" size="md" light={true} />
        </div>
      );
    }
    if (blogs.blogs.length > 0) {
      return (
        <BlogList
          scrollId={'scrollbaleBlogDiv'}
          fetchBlogs={fetchBlogs}
          hasMore={hasMore}
          blogs={blogs}
          user={user}
          logout={() => {
            console.log('logout');
          }}
        />
      );
    } else {
      return (
        <div className={'flex flex-1 justify-center items-center'}>
          <h3 className={textWhiteFontSemibold}>No Blog Found</h3>
        </div>
      );
    }
  };

  return (
    <div className={'flex flex-col w-full'}>
      <div>
        <div className={'flex md:flex-row flex-col flex-3 px-10 py-5 md:px-20'}>
          <div
            className={'flex flex-1 md:items-center justify-start mb-3 md:mb-0'}
          >
            <h1 className={'text-white font-semibold'}>Latest Web3 Blogs</h1>
          </div>
          {/*<div className={'flex flex-1 items-center justify-center w-full'}>
            <Select
              className={'w-full'}
              defaultValue={selectedOptionsForFilterBlog[0]}
              options={selectedOptionsForFilterBlog}
              onChange={(changed) => onChangeSelectedFilter(changed)}
              hideSelectedOptions={true}
              isSearchable={false}
            />
          </div>*/}
          <div
            className={'flex flex-1 items-center justify-end w-full md:ml-5'}
          >
            <div className="flex w-full ">
              <div className="relative w-full">
                <input
                  type="search"
                  id="search_blog"
                  className="block w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                  placeholder="what is nft ?"
                />
                <button
                  type="submit"
                  className="absolute top-0 right-0 p-2.5 h-full text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={async () => {
                    await updateSearch();
                  }}
                >
                  <HiSearch />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id={'scrollbaleBlogDiv'}
        className={
          'overflow-y-scroll scrollbar-hide max-h-[calc(100vh_-_282px)] min-h-[calc(100vh_-_282px)] md:max-h-[calc(100vh_-_214px)] md:min-h-[calc(100vh_-_214px)]'
        }
      >
        {renderBlogList()}
      </div>
    </div>
  );
}

export default ListingBlogsContainer;
