import React from 'react';

export interface TextSimpleProps {
  text: string;
  className: string;
  onClick: React.MouseEventHandler<HTMLParagraphElement> | undefined;
}

function TextSimple({ text, className, onClick }: TextSimpleProps) {
  return (
    <div>
      <p
        className={`text-sm font-medium text-gray-900 ${className}`}
        onClick={onClick}
      >
        {text}
      </p>
    </div>
  );
}

export default TextSimple;
