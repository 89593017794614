import { API_URL } from "@inweb3/frontend/src/configs/config";
import axios from "axios";

const endpointUserJobs = `${API_URL}/jobs`;

const getAllUserJobs = async () => {
  const { data, status } = await axios.get(endpointUserJobs + '/listing');
  console.log(data);
  return { data, status };
};

export const userJobsService = {
  getAllUserJobs,
};
