import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ErrorResponse } from '../types/api/errors';

type ResponseInResquestor = {
  error: boolean;
  data: Record<string, any>;
  status: number;
};

enum TypeContent {
  JSON = 'application/json',
}

class Header {
  header: AxiosRequestHeaders = {};
  constructor() {
    return new Header();
  }
  withBearerAuthorizaiton(token: string) {
    this.header['Authorization'] = `Bearer ${token}`;
    return this;
  }
  withTypeContent(typeContent: TypeContent) {
    this.header['Content-type'] = typeContent.toString();
    return this;
  }
}

/**
 * used in front and back, help to have the ResponseInResquestor schema format
 * @param response
 * @deprecated not to be used
 */
function returnResponse(response: AxiosResponse): ResponseInResquestor {
  let obj: ResponseInResquestor;
  const status = response.status;
  const res = {
    status: status,
    data: response.data,
  };
  if (status < 300 && status > 199) {
    obj = { error: false, ...res };
  } else {
    obj = { error: true, ...res };
  }
  return obj;
}

/**
 * used in front when we get data from axios then passed here to execute some treatement
 * @deprecated the method should not be used
 */
function handleResponse(
  response: AxiosResponse,
  callBackSuccess: (data: any, status: number) => void,
  callBackFailure: (data: Record<string, any>, status: number) => void,
): number {
  const responseInRequester = returnResponse(response);
  const { data, status, error } = responseInRequester;
  if (error) {
    callBackFailure(data, status);
  } else {
    callBackSuccess(data, status);
  }
  return status;
}

const getMessageFromErrorAxios = (err: any): ErrorResponse => {
  const response = err.response;
  return {
    message: response.data.message,
    status: err.response.status,
  } as ErrorResponse;
};

export const requestService = {
  Header,
  TypeContent,
  handleResponse,
  returnResponse,
  getMessageFromErrorAxios,
};
