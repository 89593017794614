import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from '../sidebar/SideBar';
import ContainerBody from '@inweb3/component/src/common_layouts/ContainerBody';

function DevsContainer() {
  return (
    <ContainerBody applyOverflow={true}>
      <div className={'flex flex-1 md:flex-6 flex-row h-full'}>
        <div className={'-translate-x-72 md:-translate-x-0 hidden lg:flex'}>
          <SideBar className={''} inNav={false} />
        </div>
        <Outlet></Outlet>
      </div>
    </ContainerBody>
  );
}

export default DevsContainer;
