import { useSelector } from 'react-redux';
import { RootState } from './store';

export function mySelectors() {
  return {
    user: useSelector((state: RootState) => state.user),
    system: useSelector((state: RootState) => state.system),
    topic: useSelector((state: RootState) => state.topic),
  };
}
